import { SiteFactoryAdminConfiguration } from ".";
import { ConfigurationRunType, HexColorRunType } from "./Runtypes";

export function initConfig(
  config: SiteFactoryAdminConfiguration
): SiteFactoryAdminConfiguration {
  return enforceDefaults(ConfigurationRunType.check(config));
}

function enforceDefaults(
  config: SiteFactoryAdminConfiguration
): SiteFactoryAdminConfiguration {
  if (!config.environmentMainColor) {
    // Set a fallback color.
    config.environmentMainColor = HexColorRunType.check("#2196f3");
  }
  return config;
}
