import * as icons from "@mui/icons-material";
import React from "react";
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  RaRecord,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  Show,
  ShowButton,
  SimpleShowLayout,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import JSONPretty from "react-json-pretty";
import { Site } from "../@types/sitefactory-types";
import { SFDefaultPager } from "../components";
import roles from "../userSiteRelation/roles";
import { AddTabResourceButton } from "./AddTabResourceJobButton";
import CloneSiteButton from "./CloneSiteButton";
import DomainsField from "./fields/DomainsField";
import { StatusField } from "./fields/SiteStatus";
import { SiteStatisticsShow } from "../siteStatistics/SiteStatisticsShow";
import { SiteStatusShow } from "../siteStatus/SiteStatusShow";

const ShowSiteLock = () => {
  const record = useRecordContext<Site>();
  if (!record.siteLock) return null;
  return (
    <SimpleShowLayout>
      <TextField source="siteLockByUser" label="Site locked by user" />
      <TextField source="siteLockReason" />
      <DateField source="siteLockExpiry" showTime />
    </SimpleShowLayout>
  );
};

const ShowBasicAuth = () => {
  const record = useRecordContext<Site>();
  if (!record.authEnabled) return null;
  return (
    <SimpleShowLayout>
      <TextField label="Basic auth username" source="authUsername" />
      <TextField label="Basic auth password" source="authPassword" />
    </SimpleShowLayout>
  );
};

export const SiteShow: React.FC = (props) => {
  return (
    <Show
      {...props}
      actions={
        <TopToolbar>
          <CloneSiteButton />
          <EditButton />
        </TopToolbar>
      }
    >
      <TabbedShowLayout>
        <Tab label="general info">
          <TextField source="name" label="" />
          <TextField source="displayName" label="name" />
          <RichTextField
            source="siteDescription"
            label="Site description"
            emptyText="(empty)"
          />
          <ReferenceField
            label="Status"
            reference="siteStatus"
            source="id"
            emptyText="(unknown)"
            link={(record) => `/sites/${record.id}/show/status`}
          >
            <StatusField />
          </ReferenceField>
          <ReferenceField
            source="releaseID"
            label="Release"
            reference="releases"
            link="show"
          >
            <TextField source="id" />
          </ReferenceField>
          <TextField
            source="customer"
            label="Customer for the site"
            emptyText="(empty)"
          />
          <BooleanField source="siteLock" label="Site lock enabled" />
          <ShowSiteLock />
          <TextField
            source="primaryDomain"
            label="Primary Domain (First Site Domain)"
          />
          {/*
          Something injects a label at a point between the Tab and the
          DomainsField which results in a double label. So, to avoid this we
          disable the label at this level and set the full label inside the
          component.
           */}
          <DomainsField source="siteDomain" label={false} />
          <BooleanField
            source="redirectToPrimaryDomain"
            label="Redirect to primary domain enabled"
          />
          <TextField source="backupCronSchedule" label="Backup schedule" />
          <TextField source="backupRetention" label="Backup retention" />
          <FunctionField
            label="Drupal config"
            render={(record: any) => {
              let drupalConfig;
              if (record?.drupalConfig) {
                try {
                  drupalConfig = JSON.parse(record.drupalConfig);
                } catch (err) {}
              }
              if (drupalConfig) {
                return <JSONPretty data={drupalConfig} />;
              }
            }}
          />
          <BooleanField label="Failover enabled" source="failoverPageEnabled" />
          <BooleanField label="Basic auth enabled" source="authEnabled" />
          <ShowBasicAuth />
          <DateField source="creationTimestamp" showTime />
          <DateField source="updatedTimestamp" showTime />
        </Tab>
        <Tab label="access" path="access">
          <ReferenceManyField
            label={false}
            reference="userSiteRelations"
            target="siteId"
            pagination={<SFDefaultPager />}
            // ReferenceManyField comes with it's own default for perPage so we
            // have to override.
            perPage={10}
            sort={{ field: "userId", order: "ASC" }}
          >
            <Datagrid>
              <TextField label="User" source="userId" />
              <ArrayField label="Roles" source="userRoles">
                <SingleFieldList linkType={false}>
                  <FunctionField
                    render={(role: any) => {
                      const roleDisplayName = roles.find(
                        (row: RaRecord) => row.id === role
                      )?.name;
                      return (
                        <ChipField
                          source="name"
                          record={{ name: roleDisplayName }}
                        />
                      );
                    }}
                  />
                </SingleFieldList>
              </ArrayField>
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <AddTabResourceButton
            label="Add user access"
            tabResource="userSiteRelations"
            icon={<icons.PersonAdd />}
          />
        </Tab>
        <Tab label="Status" path="status">
          <SiteStatusShow />
        </Tab>
        <Tab label="Statistics" path="statistics">
          <SiteStatisticsShow />
        </Tab>
        <Tab label="Backup jobs" path="backup-jobs">
          <ReferenceManyField
            label={false}
            reference="backupJobs"
            target="siteId"
            sort={{ field: "created", order: "DESC" }}
            pagination={<SFDefaultPager />}
            // ReferenceManyField comes with it's own default for perPage so we
            // have to override.
            perPage={10}
          >
            <Datagrid>
              <TextField label="Status" source="status" />
              <TextField label="Created" source="created" />
              <TextField label="Completed" source="completionTime" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
          <AddTabResourceButton
            label="Create Backup"
            tabResource="backupJobs"
            icon={<icons.Backup />}
          />
        </Tab>
        <Tab label="Restore jobs" path="restore-jobs">
          <ReferenceManyField
            label={false}
            reference="restoreJobs"
            target="siteId"
            sort={{ field: "created", order: "DESC" }}
            pagination={<SFDefaultPager />}
            // ReferenceManyField comes with it's own default for perPage so we
            // have to override.
            perPage={10}
          >
            <Datagrid>
              <TextField label="Status" source="status" />
              <TextField label="Target" source="restoreFileName" />
              <TextField label="Created" source="created" />
              <TextField label="Completed" source="completionTime" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
          <AddTabResourceButton
            label="Restore site from backup"
            tabResource="restoreJobs"
            icon={<icons.Restore />}
          />
        </Tab>
        <Tab label="Generate admin login" path="admin-logins">
          <ReferenceManyField
            label={false}
            reference="loginJobs"
            target="siteId"
            sort={{ field: "created", order: "DESC" }}
            pagination={<SFDefaultPager />}
            // ReferenceManyField comes with it's own default for perPage so we
            // have to override.
            perPage={10}
          >
            <Datagrid>
              <TextField label="Status" source="status" />
              <TextField label="Created" source="created" />
              <TextField label="Completed" source="completionTime" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
          <AddTabResourceButton
            label="Generate login"
            tabResource="loginJobs"
            icon={<icons.Login />}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
