import { AuthProvider, fetchUtils } from "ra-core";
import { useEffect, useState } from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { Route } from "react-router-dom";
import AzureADLoginPage from "./azure/AzureADLoginPage";
import authProviderFactory from "./azure/azureAuthProvider";
import backupJob from "./backupJob";
import { SFLayout } from "./components/SFLayout";
import { initConfig, SiteFactoryAdminConfiguration } from "./config";
import NestCrudDataProvider from "./data-provider/nest-crud-data-provider";
import FrontGate from "./FrontGate";
import jobLogs from "./jobLogs";
import loginJob from "./loginJob";
import release from "./release";
import restoreJob from "./restoreJob";
import site from "./site";
import siteStatus from "./siteStatus";
import { WebplatformTheme } from "./theme";
import user from "./user";
import userSiteRelation from "./userSiteRelation";
import { ModuleOverview } from "./moduleOverview/ModuleOverview";
import { StatisticsShow } from "./profileStatistics/ProfileStatisticsShow";

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("sitefactory_api_token");
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  } else {
    console.debug("Http client is running without a valid bearer token");
  }
  return fetchUtils.fetchJson(url, options);
};

/**
 * Outer component that we always render, it will subsequently render the rest
 * of our components if all the necessary config is available.
 */
function AppLoadConfig(): JSX.Element {
  const [config, setConfig] = useState<SiteFactoryAdminConfiguration | null>(
    null
  );
  useEffect(() => {
    // Go fetch the environment configuration.
    fetch(`${window.location.origin}/config.json`)
      .then((res) => res.json())
      .then((value) => {
        return setConfig(initConfig(value));
      })
      .catch((err) => {
        console.error("Unable to fetch sitefactory admin configuration");
        console.error(err);
      });
  }, []);

  // Config not yet ready.
  if (!config) return <FrontGate>loading config...</FrontGate>;

  // Ready, now render a component that (optionally) will set up the auth
  // provider.
  return <AppConfigureAuthProvider config={config} />;
}

type AppAuthProviderProps = {
  /**
   * A loaded sitefactory admin configuration.
   */
  config: SiteFactoryAdminConfiguration;
};
function AppConfigureAuthProvider({ config }: AppAuthProviderProps) {
  const [provider, setProvider] = useState<AuthProvider | null | "disabled">(
    null
  );
  const [message, setMessage] = useState<string>("Authenticating ...");

  useEffect(() => {
    if (config?.authMode === "AzureAD") {
      const authProvider: AuthProvider = authProviderFactory(config);
      authProvider.msalInstance
        .handleRedirectPromise()
        .then(() => {
          setProvider(authProvider);
        })
        .catch((error: Error) => {
          setMessage(
            "Error while authenticating " + error.message + ", reload to retry"
          );
          console.error("Error from coming back from redirect", error);
        });
    } else {
      console.log("AzureAD disabled, not setting provider");
      setProvider("disabled");
    }
  }, [config]);

  // We have not yet determined how to setup the auth provider.
  if (!provider) {
    return <FrontGate>{message}</FrontGate>;
  }

  // Authprovider ready (or disabled), render the actual app.
  return <App authProvider={provider} config={config} />;
}

type AppProps = {
  authProvider: AuthProvider | "disabled";
  config: SiteFactoryAdminConfiguration;
};
const App = ({ authProvider, config }: AppProps) => {
  return (
    <Admin
      theme={WebplatformTheme(config)}
      loginPage={AzureADLoginPage}
      // Only setup the auth provider if authentication is enabled.
      authProvider={authProvider === "disabled" ? undefined : authProvider}
      dataProvider={NestCrudDataProvider(
        config.sitefactoryApiEndpoint,
        httpClient
      )}
      disableTelemetry
      layout={SFLayout}
    >
      <Resource name="sites" {...site(config)} />
      <Resource name="releases" {...release} />
      <Resource name="users" {...user} />
      <CustomRoutes>
        <Route path="/profileStatistics*" element={<StatisticsShow />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="/modules" element={<ModuleOverview />} />
      </CustomRoutes>
      <Resource name="userSiteRelations" {...userSiteRelation(config)} />
      <Resource name="backupJobs" {...backupJob} />
      <Resource name="loginJobs" {...loginJob} />
      <Resource name="restoreJobs" {...restoreJob} />
      <Resource name="logs" {...jobLogs} />
      <Resource name="siteStatus" {...siteStatus} />
    </Admin>
  );
};

export default AppLoadConfig;
