import { Toolbar } from "@mui/material";
import { SetStateAction, useState } from "react";
import { SelectInput, useDataProvider, useGetOne } from "react-admin";
import { useQuery } from "react-query";
import { useForm, FormProvider } from "react-hook-form";
import { SiteListStatistics } from "../@types/sitefactory-types";
import { SiteListNodes, SiteListParagraphs } from "./SiteListTables";

interface profileFilterProps {
  setProfileFilter: React.Dispatch<SetStateAction<string>>;
  profileChoices: any[] | undefined;
}
interface nodeFilterProps {
  setNodeFilter: React.Dispatch<SetStateAction<string>>;
  nodeChoices: any[] | undefined;
}
interface paragraphFilterProps {
  setParagraphFilter: React.Dispatch<SetStateAction<string>>;
  setNodeParagraphFilter: React.Dispatch<SetStateAction<string>>;
  paragraphChoices: any[] | undefined;
  nodeParagraphChoices: any[] | undefined;
}

// Toolbar with profile dropdown filter.
function ProfileDropdown(props: profileFilterProps) {
  const methods = useForm();
  return (
    <Toolbar disableGutters>
      <FormProvider {...methods}>
        <SelectInput
          sx={{ minWidth: 250 }}
          source="profile"
          defaultValue={"all-profiles"}
          choices={props.profileChoices}
          emptyText={"All profiles"}
          emptyValue={"all-profiles"}
          onChange={(event) => props.setProfileFilter(event.target.value)}
          helperText={false}
        />
      </FormProvider>
    </Toolbar>
  );
}

// Toolbar with node dropdown filter.
function NodeDropdown(props: nodeFilterProps) {
  const methods = useForm();
  return (
    <Toolbar disableGutters>
      <FormProvider {...methods}>
        <SelectInput
          sx={{ minWidth: 250 }}
          source="node type"
          defaultValue={"all-nodes"}
          choices={props.nodeChoices}
          emptyText={"All nodes"}
          emptyValue={"all-nodes"}
          onChange={(event) => props.setNodeFilter(event.target.value)}
          helperText={false}
        />
      </FormProvider>
    </Toolbar>
  );
}

// Toolbar with paragraph dropdown filters.
function ParagraphDropdown(props: paragraphFilterProps) {
  const methods = useForm();
  return (
    <Toolbar disableGutters>
      <FormProvider {...methods}>
        <SelectInput
          sx={{ minWidth: 250, mr: "16px" }}
          source="node type"
          defaultValue={"all-nodes"}
          choices={props.nodeParagraphChoices}
          emptyText={"All nodes"}
          emptyValue={"all-nodes"}
          onChange={(event) => props.setNodeParagraphFilter(event.target.value)}
          helperText={false}
        />
        <SelectInput
          sx={{ minWidth: 250, mr: "16px" }}
          source="paragraph type"
          defaultValue={"all-paragraphs"}
          choices={props.paragraphChoices}
          emptyText={"All paragraphs"}
          emptyValue={"all-paragraphs"}
          onChange={(event) => props.setParagraphFilter(event.target.value)}
          helperText={false}
        />
      </FormProvider>
    </Toolbar>
  );
}

export const StatisticSiteList = (): JSX.Element => {
  const [profileFilter, setProfileFilter] = useState("all-profiles");
  const [paragraphFilter, setParagraphFilter] = useState("all-paragraphs");
  const [nodeFilter, setNodeFilter] = useState("all-nodes");
  const [nodeParagraphFilter, setNodeParagraphFilter] = useState("all-nodes");
  const [openNode, setOpenNode] = useState(false);
  const [openParagraph, setOpenParagraph] = useState(false);

  const profiles: any[] | undefined = [];
  const paragraphs: any[] | undefined = [];
  const nodes: any[] | undefined = [];
  let nodeReports: JSX.Element;
  let paragraphReports: JSX.Element;
  const dataProvider = useDataProvider();

  // Fetch and render profile list.
  const {
    data: profilesData,
    isLoading: profilesIsLoading,
    error: profilesError,
  } = useQuery<{ data: { id: string; values: string[] } }>(
    ["labelList", { id: "profile" }],
    () => dataProvider.getLabelList("profile")
  );
  if (
    !profilesIsLoading &&
    !profilesError &&
    profilesData !== undefined &&
    profilesData
  ) {
    profilesData.data.values?.forEach((profile: any) => {
      profiles.push({ id: profile, name: profile });
    });
  }

  // Fetch and render node list.
  const {
    data: nodesData,
    isLoading: nodesIsLoading,
    error: nodesError,
  } = useQuery<{ data: { id: string; values: string[] } }>(
    ["labelList", { id: "node_type", profile: profileFilter }],
    () => dataProvider.getLabelList("node_type", profileFilter)
  );
  if (!nodesIsLoading && !nodesError && nodesData !== undefined && nodesData) {
    nodesData.data.values?.forEach((node: any) => {
      nodes.push({ id: node, name: node });
    });
  }

  // Fetch and render paragraph list.
  const {
    data: paragraphsData,
    isLoading: paragraphsIsLoading,
    error: paragraphsError,
  } = useQuery<{ data: { id: string; values: string[] } }>(
    [
      "labelList",
      {
        id: "paragraph_type",
        profile: profileFilter,
        node: nodeParagraphFilter,
      },
    ],
    () =>
      dataProvider.getLabelList(
        "paragraph_type",
        profileFilter,
        nodeParagraphFilter
      )
  );
  if (
    !paragraphsIsLoading &&
    !paragraphsError &&
    paragraphsData !== undefined &&
    paragraphsData
  ) {
    paragraphsData.data.values?.forEach((paragraph: any) => {
      paragraphs.push({ id: paragraph, name: paragraph });
    });
  }

  const profileDropdown = (
    <ProfileDropdown
      setProfileFilter={setProfileFilter}
      profileChoices={profiles}
    />
  );
  const nodeDropdown = (
    <NodeDropdown setNodeFilter={setNodeFilter} nodeChoices={nodes} />
  );
  const paragraphDropdown = (
    <ParagraphDropdown
      setParagraphFilter={setParagraphFilter}
      setNodeParagraphFilter={setNodeParagraphFilter}
      paragraphChoices={paragraphs}
      nodeParagraphChoices={nodes}
    />
  );

  // Fetch and render metrics.
  const {
    data: metricsData,
    isLoading: metricsIsLoading,
    error: metricsError,
  } = useGetOne<SiteListStatistics>("siteListStatistics", {
    id:
      profileFilter +
      "__" +
      nodeFilter +
      "__" +
      nodeParagraphFilter +
      "__" +
      paragraphFilter,
  });

  if (metricsIsLoading) {
    nodeReports = <p>Loading statistics...</p>;
    paragraphReports = <p>Loading statistics...</p>;
  } else if (metricsError || metricsData === undefined) {
    nodeReports = <p>Error when loading statistics</p>;
    paragraphReports = <p>Error when loading statistics</p>;
  } else {
    if (metricsData.metrics.length < 1) {
      nodeReports = (
        <p>
          No values for {profileFilter}, kk_statistic module is likely not
          installed.
        </p>
      );
      paragraphReports = (
        <p>
          No values for {profileFilter}, kk_statistic module is likely not
          installed.
        </p>
      );
    } else {
      nodeReports = (
        <SiteListNodes
          metricsData={metricsData}
          open={openNode}
          setOpen={setOpenNode}
        />
      );
      paragraphReports = (
        <SiteListParagraphs
          metricsData={metricsData}
          open={openParagraph}
          setOpen={setOpenParagraph}
        />
      );
    }
  }

  return (
    <span>
      {profileDropdown}
      <h3>Node utilization</h3>
      {nodeDropdown}
      {nodeReports}
      <h3>Paragraph utilization</h3>
      {paragraphDropdown}
      {paragraphReports}
    </span>
  );
};
