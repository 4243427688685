import { Fragment } from "react";
import {
  BooleanField,
  BulkDeleteWithConfirmButton,
  Datagrid,
  ListProps,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { Site } from "../@types/sitefactory-types";
import { SFList } from "../components";
import { SiteFactoryAdminConfiguration } from "../config";
import BulkUpdateReleaseButton from "./BulkUpdateReleaseButton";
import BulkExportSiteInformation from "./BulkExportSiteInformation";
import BulkExportSiteUserRelations from "./BulkExportSiteUserRelations";
import Button from "@mui/material/Button";
import ContentAdd from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import CompactDomainsField from "./fields/CompactDomainsField";
import { StatusField } from "./fields/SiteStatus";

export function siteListFactory(config: SiteFactoryAdminConfiguration) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const ListActions = (props: any) => (
    <TopToolbar>
      <Button
        startIcon={<ContentAdd />}
        component={Link}
        to={{
          pathname: "/sites/create",
        }}
      >
        Create
      </Button>
    </TopToolbar>
  );

  const SiteListBulkActionButtons = (props: any) => (
    <Fragment>
      <BulkExportSiteUserRelations />
      <BulkExportSiteInformation {...config} />
      <BulkUpdateReleaseButton />
      <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
  );

  const postFilters = [
    <TextInput
      key="search"
      label="Search"
      source="q"
      alwaysOn
      resettable={true}
    />,
    <TextInput
      key="name"
      label="Name"
      source="name"
      alwaysOn
      resettable={true}
    />,
    <TextInput
      key="release"
      label="Release"
      source="releaseID"
      alwaysOn
      resettable={true}
    />,
    <TextInput
      key="domain"
      label="Site domain"
      source="allDomains"
      alwaysOn
      resettable={true}
    />,
  ];

  // TODO, switch to official React Admin types to gain access to the "real"
  // list props type.
  const SiteList = (props: ListProps<Site>) => {
    return (
      <SFList
        {...props}
        actions={<ListActions />}
        filters={postFilters}
        sort={{ field: "displayName", order: "ASC" }}
      >
        <Datagrid
          rowClick="show"
          bulkActionButtons={<SiteListBulkActionButtons />}
        >
          <TextField source="displayName" label="Name" />
          <CompactDomainsField label="Primary Domain" />
          <ReferenceField
            source="releaseID"
            label="Release"
            reference="releases"
            link="show"
          >
            <TextField source="id" />
          </ReferenceField>
          <BooleanField source="failoverPageEnabled" label="Failover" />
          <ReferenceField
            label="Status"
            reference="siteStatus"
            source="id"
            emptyText="(unknown)"
            link={(record) => `/sites/${record.id}/show/status`}
          >
            <StatusField />
          </ReferenceField>
        </Datagrid>
      </SFList>
    );
  };
  return SiteList;
}
