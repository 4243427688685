import { SiteFactoryAdminConfiguration } from "../config";
import { userSiteRelationCreateFactory } from "./UserSiteRelationCreate";
import { userSiteRelationEditFactory } from "./UserSiteRelationEdit";

const userSiteRelation = (config: SiteFactoryAdminConfiguration) => {
  return {
    create: userSiteRelationCreateFactory(config),
    edit: userSiteRelationEditFactory(config),
  };
};

export default userSiteRelation;
