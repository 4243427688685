import SiteIcon from "@mui/icons-material/Language";
import { SiteFactoryAdminConfiguration } from "../config";
import { siteCreate } from "./SiteCreate";
import { SiteEdit } from "./SiteEdit";
import { siteListFactory } from "./SiteList";
import { SiteShow } from "./SiteShow";

const site = (config: SiteFactoryAdminConfiguration) => {
  return {
    icon: SiteIcon,
    list: siteListFactory(config),
    create: siteCreate(config),
    show: SiteShow,
    edit: SiteEdit,
  };
};

export default site;
