import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useRecordContext } from "react-admin";
import { SiteStatusSummary } from "../../@types/sitefactory-types";

export const StatusField = () => {
  const record = useRecordContext<SiteStatusSummary>();

  let icon;

  switch (record.statusName) {
    case "OK": {
      icon = (
        <DoneIcon color="success" data-status-value="0" data-status-name="ok" />
      );
      break;
    }

    case "WARN": {
      icon = (
        <WarningAmberIcon
          color="warning"
          data-status-value="1"
          data-status-name="warn"
        />
      );
      break;
    }

    case "ERROR": {
      icon = (
        <ErrorIcon
          color="error"
          data-status-value="2"
          data-status-name="error"
        />
      );
      break;
    }
  }

  return <div>{icon}</div>;
};
