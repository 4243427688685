import * as msal from "@azure/msal-browser";

import { AuthProvider } from "ra-core";
import { SiteFactoryAdminConfiguration } from "../config";

/**
 * Validate the configuration with regards to Azure.
 */
function getAzureConfigOrFail(configuration: SiteFactoryAdminConfiguration) {
  if (!configuration.azureAdConfig) {
    throw new Error("Invalid configuration, missing azureAdConfig");
  }
  if (!configuration.azureAdConfig.azureAdAppId) {
    throw new Error(
      "Configuration error - azureAdConfig.azureAdAppId is missing"
    );
  }
  if (!configuration.azureAdConfig.azureAdAppScope) {
    throw new Error(
      "Configuration error - azureAdConfig.azureAdAppScope is missing"
    );
  }
  if (!configuration.azureAdConfig.azureAuthoritativeAd) {
    throw new Error(
      "Configuration error - azureAdConfig.azureAuthoritativeAd is missing"
    );
  }
  return configuration.azureAdConfig;
}

const azureAuthProvider = (
  configuration: SiteFactoryAdminConfiguration
): AuthProvider & { msalInstance: msal.PublicClientApplication } => {
  const azureAdConfig = getAzureConfigOrFail(configuration);
  const msalConfig: msal.Configuration = {
    auth: {
      clientId: azureAdConfig.azureAdAppId,
      authority: azureAdConfig.azureAuthoritativeAd,
    },
  };
  const msalInstance = new msal.PublicClientApplication(msalConfig);

  const authParameters = {
    scopes: [azureAdConfig.azureAdAppScope],
  };

  return {
    msalInstance,
    // Login is invoked from our Login-page that has a single "Log in" button.
    // The task now is to trigger a full login flow via MSAL.
    // Check whether we already have an application state, if not, redirect.
    login: async () => {
      try {
        const states = msalInstance.getAllAccounts();
        if (!states || states.length === 0) {
          // This is sync and will trigger a full redirect.
          return msalInstance.loginRedirect(authParameters);
        }

        // We're logged in, return a void promise.
        return Promise.resolve();
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    // Called when the user clicks on the logout button
    logout: async () => {
      const states = msalInstance.getAllAccounts();
      // Log the user out if we have state
      if (states && states.length > 0) {
        // Trigger a logout.
        await msalInstance.logout();
        // Have RA show the frontpage. it will just show for a second before msal redirects the browser.
        return Promise.resolve("/");
      }

      return Promise.resolve();
    },

    // called when the API returns an error
    checkError: (error: any) => {
      if (
        error &&
        error.status &&
        (error.status === 401 || error.status === 403)
      ) {
        console.info(
          "Authentication rejected from api, logging out " +
            (error.message ? '"' + error.message + '"' : "")
        );
        return Promise.reject();
      }

      console.debug("checkError called with non-auth-related error");
      console.debug({ error });

      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    // When rejecting RA will perform a logout.
    checkAuth: async () => {
      const accounts = msalInstance.getAllAccounts();

      // Reject if we don't have an active account.
      if (!accounts || accounts.length === 0) {
        throw new Error();
      }

      // We have a user, fetch an access-token - if it is not expired msal will just get it from the cache.
      try {
        const silentRequest = {
          account: accounts[0],
          scopes: authParameters.scopes,
        };

        // We expand the idTokenClaims from a plain object to any so we at the
        // very least can access the various properties after checking them.
        const accessTokenResponse: msal.AuthenticationResult & {
          idTokenClaims: any;
        } = await msalInstance.acquireTokenSilent(silentRequest);
        // Verify we got the groups back.
        if (
          accessTokenResponse.idTokenClaims &&
          accessTokenResponse.idTokenClaims.aud &&
          accessTokenResponse.idTokenClaims.aud === azureAdConfig.azureAdAppId
        ) {
          if (
            accessTokenResponse.scopes &&
            accessTokenResponse.scopes.find(
              (element) => element === azureAdConfig.azureAdAppScope
            )
          ) {
            // We could ping the api to set the token securely using eg.
            // api/v1/me. Local storage will do for now.
            localStorage.setItem(
              "sitefactory_api_token",
              accessTokenResponse.accessToken
            );
            return;
          } else {
            const msg = `Got Auth token, but could not find an id-token claim for the app audience ${azureAdConfig.azureAdAppId} and a scope matching ${azureAdConfig.azureAdAppScope}`;
            throw new Error(msg);
          }
        } else {
          const msg = "Could not validate the returned access token";
          console.error(msg + " %o", accessTokenResponse);
          throw new Error(msg);
        }
      } catch (error) {
        console.error(
          "Halting attempt to get access token due to error %o",
          error
        );
        throw error;
      }
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: async () => {
      // We do not currently differentiate users once they are logged in.
      return Promise.resolve();
    },
    /**
     * Provide details on the users identity when logged in.
     */
    getIdentity: () => {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        const account = accounts[0];
        return Promise.resolve({
          id: account.localAccountId,
          fullName: account.name || "",
          avatar: "",
        });
      }
      return Promise.reject();
    },
  };
};

export default azureAuthProvider;
