import { RichTextInput } from "ra-input-rich-text";
import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import * as PWG from "../lib/gpw";

import { useLocation } from "react-router";
import { SiteFactoryAdminConfiguration } from "../config";

export function siteCreate(config: SiteFactoryAdminConfiguration) {
  const SiteCreate: React.FC = (props) => {
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    // TODO
    const clonedFromSite = search.get("clonedFromSite");

    // Strip out any null or empty properties.
    const submitTransformer = (data: any) => {
      const filtered = Object.fromEntries(
        Object.entries(data).filter(([_, v]) => v != null && v !== "")
      );
      return filtered;
    };

    const defaultValues = {
      ...config.siteDefaults,
      ...(config.defaultBasicAuth
        ? {
            authEnabled: true,
            authUsername: PWG.pronounceable(8),
            authPassword: PWG.pronounceable(8),
          }
        : {}),
      clonedFromSite,
    } as const;

    return (
      <Create {...props} redirect="show" transform={submitTransformer}>
        {/* TODO */}
        <SimpleForm defaultValues={defaultValues} maxWidth={1200}>
          <TextInput
            source="name"
            label="Short name"
            helperText="A simple lowercased, alpha-numeric name for the site, eg: 'svoemkbh'."
            fullWidth={true}
          />
          <TextInput
            source="displayName"
            label="Display name"
            helperText="Longer, human friendly name of the site, eg. 'Svøm KBH'."
            fullWidth={true}
          />
          <RichTextInput
            source="siteDescription"
            label="Site description"
            helperText="Custom descriptions for site"
            fullWidth={true}
          />
          <TextInput
            source="backupCronSchedule"
            label="Backup cron schedule"
            helperText="Input how often backups should happen using crontab syntax, e.g. ”0 3 * * *”. Default is once per day."
            fullWidth={true}
          />
          <NumberInput
            source="backupRetention"
            label="Backup retention"
            max={365}
            min={0}
            helperText="Select the number of backups to retain before deleting old backups"
            fullWidth={true}
          />
          <ReferenceInput
            source="storageProviderName"
            reference="storageProviders"
            perPage={10}
          >
            <AutocompleteInput
              optionText="id"
              label="Storage provider"
              fullWidth={true}
            />
          </ReferenceInput>
          <ReferenceInput source="releaseID" reference="releases" perPage={500}>
            <AutocompleteInput
              optionText="id"
              label="Release"
              fullWidth={true}
            />
          </ReferenceInput>
          <TextInput
            source="customer"
            label="Customer for the site"
            helperText="Lower-cased alpha-numeric identifier for the customer of the site. May be left blank. Depending on the environment configuration, sites for different customers may be handled differently."
            fullWidth={true}
          />
          <BooleanInput
            label="Enable site lock"
            source="siteLock"
            helperText="If enabled, the site is locked and cannot be deleted."
            fullWidth={true}
          />
          <TextInput
            source="siteLockByUser"
            label="Site locked by user"
            helperText="The user who locked the site."
            fullWidth={true}
          />
          <TextInput
            source="siteLockReason"
            label="Site lock reason"
            helperText="The reason a site is locked."
            fullWidth={true}
          />
          <DateTimeInput
            source="siteLockExpiry"
            label="Site lock expiry"
            helperText="The date the lock should expire."
            fullWidth={true}
          />
          <FormDataConsumer>
            {({ formData }: any) => {
              return (
                <ArrayInput
                  source="siteDomains"
                  label={`Site domain(s) (Defaults to ${formData.systemDomain} if none are added)`}
                >
                  <SimpleFormIterator fullWidth={true}>
                    <TextInput
                      source="domain"
                      label="Site domain"
                      fullWidth={true}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              );
            }}
          </FormDataConsumer>
          <BooleanInput
            label="Enable redirect to primary domain"
            source="redirectToPrimaryDomain"
            helperText="Will enable automatic redirect from any site domain to the primary domain."
            defaultValue={true}
            fullWidth={true}
          />
          <TextInput
            multiline
            label="Drupal configuration"
            source="drupalConfig"
            helperText="Must be valid JSON. Use config:name to set $config['name'], config:name:child to set $config['name']['child'] and settings:name to set $settings['name'] in settings.php file."
            fullWidth={true}
          />
          <BooleanInput
            label="Enable failover"
            source="failoverPageEnabled"
            helperText="Will set the site in failover mode, which means that it wont be accessible to users."
            fullWidth={true}
          />
          <BooleanInput
            label="Enable Basic Auth"
            source="authEnabled"
            helperText="If the site should be protected by basic auth."
            fullWidth={true}
          />
          <TextInput
            source="authUsername"
            label="Basic auth username"
            fullWidth={true}
          />
          <TextInput
            source="authPassword"
            label="Basic auth password"
            fullWidth={true}
          />
        </SimpleForm>
      </Create>
    );
  };

  return SiteCreate;
}
