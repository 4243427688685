import * as React from "react";
import {
  FieldProps,
  FunctionField,
  Labeled,
  useRecordContext,
} from "react-admin";
import { Site } from "../../@types/sitefactory-types";

const DomainsField: React.FC<FieldProps> = () => {
  const record = useRecordContext<Site>();
  if (record && record?.siteDomains && Array.isArray(record.siteDomains)) {
    let domains: string;
    if (record.siteDomains.length > 0) {
      domains = record.siteDomains.map((entry) => entry.domain).join(", ");
    } else {
      // Default to system domain if no site domains are set.
      domains = record.systemDomain;
    }
    return (
      <Labeled
        label={`Site domain(s) (Defaults to ${record.systemDomain} if none are added)`}
      >
        <FunctionField
          render={() => {
            return domains;
          }}
        />
      </Labeled>
    );
  }
  return null;
};

export default DomainsField;
