const roles = [
  {
    id: "editor",
    name: "Editor",
  },
  {
    id: "site_editor",
    name: "Site Editor",
  },
  {
    id: "news_editor",
    name: "News Editor",
  },
  {
    id: "tender_editor",
    name: "Tender Editor",
  },
  {
    id: "coordinating_editor",
    name: "Coordinating Editor",
  },
  {
    id: "ul_editor",
    name: "UL Editor",
  },
  {
    id: "local_editor",
    name: "Local Editor",
  },
  {
    id: "global_editor",
    name: "Global Editor",
  },
  {
    id: "webmaster",
    name: "Webmaster",
  },
  {
    id: "administrator",
    name: "Administrator",
  },
];
export default roles;
