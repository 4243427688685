import Icon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import { stringify } from "query-string";
import { useCreatePath, useRecordContext, useResourceContext } from "ra-core";
import { ButtonProps } from "ra-ui-materialui";
import { memo, ReactElement } from "react";
import { Button } from "react-admin";
import { Link } from "react-router-dom";
import { Site } from "../@types/sitefactory-types";

export const CloneSiteButton = (props: CloneButtonProps) => {
  const {
    label = "Clone site",
    scrollToTop = true,
    icon = defaultIcon,
    ...rest
  } = props;
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const createPath = useCreatePath();
  const pathname = createPath({ resource, type: "create" });
  return (
    <Button
      component={Link}
      to={
        record
          ? {
              pathname,
              search: stringify({
                clonedFromSite: record.id,
                source: JSON.stringify(clonedProperties(record)),
              }),
              state: { _scrollToTop: scrollToTop },
            }
          : pathname
      }
      label={label}
      onClick={stopPropagation}
      {...sanitizeRestProps(rest)}
    >
      {icon}
    </Button>
  );
};

const defaultIcon = <Icon />;
const clonedProperties = (record: Site) => {
  return { releaseID: record.releaseID };
};

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: any) => e.stopPropagation();

const sanitizeRestProps = ({
  ...rest
}: Omit<CloneButtonProps, "label" | "scrollToTop" | "icon">) => rest;

interface Props {
  record?: any;
  icon?: ReactElement;
  scrollToTop?: boolean;
}

export type CloneButtonProps = Props & ButtonProps;

CloneSiteButton.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
};

export default memo(CloneSiteButton);
