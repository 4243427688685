import ActionUpdate from "@mui/icons-material/Update";
import { useEffect, useState } from "react";
import {
  AutocompleteInput,
  Button,
  Confirm,
  Form,
  ReferenceInput,
  TextInput,
  useGetMany,
  useListContext,
  useNotify,
  useRefresh,
  useUnselectAll,
  useUpdate,
} from "react-admin";

const BulkUpdateReleaseButton = () => {
  let siteDescription = "";
  // Controls whether the modal is open.
  const [open, setOpen] = useState(false);
  // Whether we're in the process of performing the update.
  const [isUpdating, setIsUpdating] = useState(false);
  // The new release selected by the user in the modal.
  const [newReleaseID, setNewReleaseID] = useState("");
  // The release id referenced by the first site the user has selected. They
  // all have to be identical so first id must be == the id of the remaining
  // selection.
  const [firstReleaseID, setFirstReleaseID] = useState("");
  // Site profile name picked out from the first sites release. We cannot
  // upgrade between profile types.
  const [firstReleaseProfile, setFirstReleaseProfile] = useState("");
  // Whether the modal open button is enabled. We disable it when we detect
  // an incompatible selection - eg, sites of differing release id or site
  // profiles.
  const [buttonEnabled, setButtonEnabled] = useState(false);
  // The sites the user has selected in the list view.
  const { selectedIds } = useListContext();

  // RA Admin hooks.
  const [update] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("sites");

  // Fetch the data for all selected sites. We actually only need the release-
  // id, but we have no way of limiting what we select.
  const { data: selectedData, isLoading: selectedLoading } = useGetMany(
    "sites",
    { ids: selectedIds || [] }
  );

  // Fetch data for the releases the user want to upgrade to and from. We do
  // this to be able to compare profile-names and ensure the upgrade does not
  // attempt to move between profiles.
  // The upon opening the modal only the start-release has been selected, so
  // we need to be careful only to fetch the ids we actually have.
  const releaseIDs = (
    newReleaseID ? [firstReleaseID, newReleaseID] : [firstReleaseID]
  ).filter((e) => e);
  const { data: selectedReleasesData, isLoading: selectedReleasesLoading } =
    useGetMany("releases", { ids: releaseIDs });

  // Re-render on state changes.
  useEffect(() => {
    // We're still loading the selected sites.
    if (selectedLoading) {
      setFirstReleaseID("");
      setButtonEnabled(false);
      return;
    }

    // We don't have any data (release id) on the sites.
    if (!selectedData) {
      setFirstReleaseID("");
      setButtonEnabled(false);
      return;
    }

    // No sites selected, or the last site just got unchecked.
    if (selectedData.length === 0) {
      setFirstReleaseID("");
      setButtonEnabled(false);
      return;
    }
    setIsUpdating(false);
    const firstId = selectedData[0].releaseID;
    // Note the id of the first site that has been selected.
    setFirstReleaseID(firstId);

    let shouldEnable = true;
    // See if we're ready to enable the button.
    selectedData.forEach((site: any) => {
      // Make sure all selected sites are on the same id.
      // We do this by comparing all site to the id of the first.
      if (site.releaseID !== firstId) {
        shouldEnable = false;
      }
    });
    setButtonEnabled(shouldEnable);

    if (
      !selectedReleasesLoading &&
      selectedReleasesData &&
      selectedReleasesData[0]
    ) {
      setFirstReleaseProfile(selectedReleasesData[0].siteProfile);
    }
  }, [
    selectedIds,
    selectedLoading,
    selectedData,
    selectedReleasesData,
    selectedReleasesLoading,
  ]);

  if (selectedIds.length > 1) {
    siteDescription = `for ${selectedIds.length} sites`;
  } else {
    siteDescription = `for site`;
  }

  const handleClick = () => {
    // Clear out the previous value;
    setNewReleaseID("");
    setOpen(true);
  };
  const handleDialogClose = () => {
    // Clear out the selected release.
    setNewReleaseID("");
    setOpen(false);
  };

  // User clicked confirm, do final validation and update.
  const handleConfirm = () => {
    if (!newReleaseID) {
      notify("No Release ID was selected", { type: "warning" });
      return;
    }

    if (newReleaseID === firstReleaseID) {
      notify("Release ID must be different from the current.", {
        type: "warning",
      });
      return;
    }

    if (selectedData === undefined) {
      notify("selectedData was unexpectedly undefined. Contact developer.", {
        type: "warning",
      });
      return;
    }

    if (selectedReleasesData?.length !== 2) {
      notify(
        "Unexpected number of releases in selectedReleasesData. Contact developer.",
        { type: "warning" }
      );
      return;
    } else if (selectedReleasesLoading) {
      notify(
        "Releases are unexpectedly still loading number of releases in selectedReleasesData. Contact developer.",
        { type: "warning" }
      );
      return;
    } else {
      const newReleaseProfile: string = selectedReleasesData[1].siteProfile;

      if (firstReleaseProfile !== newReleaseProfile) {
        notify("You cannot select a Release ID with a different profile", {
          type: "warning",
        });
        return;
      }
    }

    // Generate an array of names we can use for lookup.
    const siteNames = selectedData.map((site) => site.name);

    // Prepare the update and the update function.
    const diff: object = { releaseID: newReleaseID };
    const singleUpdate = function (site: any) {
      const newSite = { ...site, ...diff };
      return update(
        "sites",
        { id: site.id, data: newSite, previousData: site },
        // We need the change to happen right away.
        { mutationMode: "pessimistic" }
      );
    };

    // Setup function that we will use do detect update errors.
    const scanForErrors = function (
      results: PromiseSettledResult<any>[],
      siteNames: any[]
    ) {
      let isError = false;
      let firstErrorMessage;
      let firstFailedSite;
      const failedNames = [];
      for (let i = 0; i < results.length; i++) {
        const result = results[i];
        const siteName = siteNames[i];

        // Promise.allSettled sets the status to either rejected or fulfilled.
        if (result.status === "rejected") {
          // Gather data about any errors.
          failedNames.push(siteName);

          // Log the error to the console as there may be a lot.
          // eslint-disable-next-line no-console
          console.error("Failed updating site " + siteName + "due to");
          // eslint-disable-next-line no-console
          console.error({ error: result.reason });

          // Capture the first error so that we can provide a little feedback
          // to the user.
          if (!isError) {
            isError = true;
            firstFailedSite = siteName;
            firstErrorMessage = result.reason;
          }
        }
      }
      return { isError, failedNames, firstFailedSite, firstErrorMessage };
    };

    // Setup the function that will go through the result of doing the update.
    const handleUpdateResult = function (results: PromiseSettledResult<any>[]) {
      // Clear out our status.
      setIsUpdating(true);

      // Determine if we had any errors.
      const { isError, failedNames, firstFailedSite, firstErrorMessage } =
        scanForErrors(results, siteNames);

      // Report status to the user
      if (isError) {
        const failedNamesString = failedNames.join(", ");
        notify(
          `Error when trying to update releases ${siteDescription}, the following sites failed ${failedNamesString}. The first error (for site ${firstFailedSite}) was "${firstErrorMessage}". Consult the console for all error-messages.`,
          { type: "error" }
        );
      } else {
        notify(
          `Site releases updated ${siteDescription}: ${siteNames.join(", ")}`
        );
      }

      // and reset our state.
      unselectAll();
      refresh();
      setOpen(false);
    };

    // Perform an update of all sites and handle the result.
    Promise.allSettled(selectedData.map(singleUpdate))
      .then(handleUpdateResult)
      .catch((e) =>
        // Rejections are unexpected for Promise.allSettled.
        notify("Unexpected error while updating " + e, { type: "error" })
      );
  };

  const onReleaseIdChange = (selectedReleaseId: any) => {
    if (!selectedReleaseId) {
      setNewReleaseID("");
    }
    setNewReleaseID(selectedReleaseId);
  };

  const releaseIDFilter =
    firstReleaseProfile !== ""
      ? { siteProfile: firstReleaseProfile, "id||$ne": firstReleaseID }
      : {};

  const confirmContent = (
    <Form
      onSubmit={onReleaseIdChange}
      defaultValues={{
        currentReleaseID: firstReleaseID,
        releaseID: newReleaseID,
      }}
    >
      <div>
        <TextInput
          source="currentReleaseID"
          label="Current Release ID"
          disabled
        />
        <ReferenceInput
          source="releaseID"
          reference="releases"
          filter={releaseIDFilter}
          perPage={500}
        >
          <AutocompleteInput
            optionText="id"
            label="New Release ID"
            onChange={onReleaseIdChange}
          />
        </ReferenceInput>
      </div>
    </Form>
  );

  const confirmTitle = `Update release ${siteDescription}`;

  return (
    <>
      <Button
        label="Update release"
        onClick={handleClick}
        disabled={!buttonEnabled}
      >
        <ActionUpdate />
      </Button>
      <Confirm
        isOpen={open}
        loading={isUpdating}
        title={confirmTitle}
        content={confirmContent}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default BulkUpdateReleaseButton;
