import UserIcon from "@mui/icons-material/People";
import { UserCreate } from "./UserCreate";
import { UserEdit } from "./UserEdit";
import { UserList } from "./UserList";
import { UserShow } from "./UserShow";

const user = {
  icon: UserIcon,
  list: UserList,
  create: UserCreate,
  show: UserShow,
  edit: UserEdit,
};

export default user;
