import ActionUpdate from "@mui/icons-material/Download";
import { format } from "date-fns/fp";
import { Button, downloadCSV, useGetList, useListContext } from "react-admin";
import jsonExport from "jsonexport/dist";
import { useEffect, useState } from "react";

const BulkExportSiteUserRelations = () => {
  // The sites the user has selected in the list view.
  const { selectedIds } = useListContext();
  // Whether button has been clicked and code is running
  const [clicked, setClicked] = useState(false);
  //  Whether the bulk export button is enabled.
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const { data: selectedData, isLoading: selectedLoading } = useGetList(
    "userSiteRelations",
    {
      pagination: { page: 1, perPage: 10000 },
      filter: {
        "siteId||$in": selectedIds.join(",") || "noChosenSites",
      },
    }
  );

  // Re-render on state changes.
  useEffect(() => {
    // We're still loading the selected sites.
    if (selectedLoading) {
      setButtonEnabled(false);
      return;
    }

    // We don't have any data (user site relations) on the sites.
    if (!selectedData) {
      setButtonEnabled(false);
      return;
    }

    // No sites selected, or the last site just got unchecked.
    if (selectedData.length === 0) {
      setButtonEnabled(false);
      return;
    }
    setButtonEnabled(true);

    if (clicked) {
      const userSiteRelationsForExport = selectedData.map(
        (userSiteRelation) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, ...userSiteRelationForExport } = userSiteRelation; // omit id
          return userSiteRelationForExport;
        }
      );
      // sort by siteId over userId
      userSiteRelationsForExport.sort((a, b) => (a.userId < b.userId ? -1 : 1));
      userSiteRelationsForExport.sort((a, b) => (a.siteId < b.siteId ? -1 : 1));
      jsonExport(
        userSiteRelationsForExport,
        {
          headers: ["siteId", "userId", "userRoles"], // order fields in the export
          rowDelimiter: ";",
        },
        (err, csv) => {
          // download as 'site-user_relations_[date].csv' file
          downloadCSV(
            csv,
            "site-user_relations_" + format("yyyy-MM-dd_HH-mm-ss", new Date())
          );
        }
      );
      setClicked(false);
    }
  }, [selectedIds, selectedLoading, selectedData, clicked]);

  const handleClick = () => {
    setClicked(true);
  };

  return (
    <>
      <Button
        label="Export access list"
        onClick={handleClick}
        disabled={!buttonEnabled}
      >
        <ActionUpdate />
      </Button>
    </>
  );
};

export default BulkExportSiteUserRelations;
