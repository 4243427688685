import { Add } from "@mui/icons-material";
import { ReactElement } from "react";
import { Button, ButtonProps, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

export const AddTabResourceButton = (props: AddTabResourceButtonProps) => {
  const record = useRecordContext(props);
  const { label, tabResource, icon = defaultIcon, ...rest } = props;
  return (
    <Button
      component={Link}
      to={`/${tabResource}/create?siteId=${record.id}`}
      label={label}
      {...rest}
    >
      {icon}
    </Button>
  );
};

const defaultIcon = <Add />;

interface Props {
  label: string;
  tabResource: string;
  icon?: ReactElement;
}

export type AddTabResourceButtonProps = Props & ButtonProps;

export default AddTabResourceButton;
