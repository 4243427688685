import { Box, Toolbar, Tab, Tabs, Paper, Typography } from "@mui/material";
import { ParagraphTable } from "../components/statistics/StatisticsParagraphTable";
import { SetStateAction, useState } from "react";
import { SelectInput, useGetOne } from "react-admin";
import { useForm, FormProvider } from "react-hook-form";
import { UtilizationMetrics } from "../@types/sitefactory-types";
import { NodeTable } from "../components/statistics/StatisticsNodeTable";
import { StatisticSiteList } from "./StatisticSiteList";
import { Link, useLocation, matchPath } from "react-router-dom";

interface filterProps {
  setProfileFilter: React.Dispatch<SetStateAction<string>>;
  choices: any[] | undefined;
}

// Toolbar with dropdown filters.
function DropdownTableToolbar(props: filterProps) {
  const methods = useForm();
  return (
    <Toolbar disableGutters>
      <FormProvider {...methods}>
        <SelectInput
          sx={{ minWidth: 250, mr: "16px" }}
          source="profile"
          defaultValue={"all-profiles"}
          choices={props.choices}
          emptyText={"All profiles"}
          emptyValue={"all-profiles"}
          onChange={(event) => props.setProfileFilter(event.target.value)}
          helperText={false}
        />
      </FormProvider>
    </Toolbar>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: string | undefined;
  value: string | undefined;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 2, paddingTop: 0, paddingBottom: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ProfileTabContent(): JSX.Element {
  const [profileFilter, setProfileFilter] = useState("all-profiles");
  const [openNode, setOpenNode] = useState(false);
  const [openParagraph, setOpenParagraph] = useState(true);
  const profiles: any[] | undefined = [];
  let paragraphReports: JSX.Element;
  let nodeReports: JSX.Element;

  // Fetch and render profile list.
  const {
    data: profilesData,
    isLoading: profilesIsLoading,
    error: profilesError,
  } = useGetOne("labelList", { id: "profile" });
  if (!profilesIsLoading && !profilesError && profilesData !== undefined) {
    profilesData.values.forEach((profile: any) => {
      profiles.push({ id: profile, name: profile });
    });
  }
  const dropdownHeader = (
    <DropdownTableToolbar
      setProfileFilter={setProfileFilter}
      choices={profiles}
    />
  );

  // Fetch and render metrics.
  const {
    data: metricsData,
    isLoading: metricsIsLoading,
    error: metricsError,
  } = useGetOne<UtilizationMetrics>("profileStatistics", {
    id: profileFilter,
  });

  if (metricsIsLoading) {
    nodeReports = <p>Loading statistics for {profileFilter}...</p>;
    paragraphReports = <p>Loading statistics for {profileFilter}...</p>;
  } else if (
    metricsError ||
    metricsData === undefined ||
    metricsData.paragraphMetrics === undefined ||
    metricsData.nodeMetrics === undefined
  ) {
    nodeReports = <p>Error when loading statistics for {profileFilter}</p>;
    paragraphReports = <p>Error when loading statistics for {profileFilter}</p>;
  } else {
    if (metricsData.nodeMetrics.length < 1) {
      nodeReports = (
        <p>
          No values for {profileFilter}, kk_statistic module is likely not
          installed.
        </p>
      );
    } else {
      nodeReports = (
        <NodeTable
          metricsData={metricsData}
          open={openNode}
          setOpen={setOpenNode}
        />
      );
    }
    if (metricsData.paragraphMetrics.length < 1) {
      paragraphReports = (
        <p>
          No values for {profileFilter}, kk_statistic module is likely not
          installed.
        </p>
      );
    } else {
      paragraphReports = (
        <ParagraphTable
          metricsData={metricsData}
          open={openParagraph}
          setOpen={setOpenParagraph}
        />
      );
    }
  }
  return (
    <span>
      {dropdownHeader}
      <h3>Node utilization</h3>
      {nodeReports}
      <h3>Paragraph utilization</h3>
      {paragraphReports}
    </span>
  );
}

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export const StatisticsShow = (): JSX.Element => {
  const routeMatch = useRouteMatch([
    "profileStatistics/sitelist",
    "profileStatistics",
  ]);
  const selectedTab = routeMatch?.pattern?.path;
  const ProfileContent = <ProfileTabContent />;
  const tabContent = <StatisticSiteList />;

  const statusReports = (
    <span>
      <Paper sx={{ marginTop: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab
              label="Profiles"
              value="profileStatistics"
              to=""
              component={Link}
            />
            <Tab
              label="SiteList"
              value="profileStatistics/sitelist"
              to="sitelist"
              component={Link}
            />
          </Tabs>
        </Box>
        <TabPanel value={selectedTab} index={"profileStatistics"}>
          {ProfileContent}
        </TabPanel>
        <TabPanel value={selectedTab} index={"profileStatistics/sitelist"}>
          {tabContent}
        </TabPanel>
      </Paper>
    </span>
  );
  return statusReports;
};
