import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import React from "react";

import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";

const classes = {
  paper: `FrontGate-paper`,
  avatar: `FrontGate-avatar`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

/**
 * Render a "front gate" component that is displayed to the user instead of the main app.
 * @param props
 */
const FrontGate: React.FC<{ children: React.ReactNode }> = (props: {
  children: React.ReactNode;
}) => {
  return (
    <StyledContainer maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          SiteFactory Admin
        </Typography>
        {props.children}
      </div>
    </StyledContainer>
  );
};

export default FrontGate;
