import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Chip from "@mui/material/Chip";
import { grey } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Severity } from "../@types/sitefactory-types";
import Grid from "@mui/material/Grid";

type MetricListProps = {
  title: string;
  children: JSX.Element[];
};
export function MetricList({ title, children }: MetricListProps) {
  return (
    <>
      <Typography
        variant="body2"
        color={grey[800]}
        textTransform="uppercase"
        component="h6"
      >
        {title}
      </Typography>
      <List>{children}</List>
    </>
  );
}

export type MetricsCategories = Record<
  string,
  {
    title: string;
    levels: Record<
      string,
      {
        value: number;
        status: Severity | "INFO";
      }
    >;
  }[]
>;
type MetricListItemProps = {
  metric: MetricsCategories[""][0];
};
export function MetricListItem({ metric }: MetricListItemProps) {
  return (
    <ListItem>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <CheckCircleIcon />
        </Grid>
        <Grid item>
          <Typography>{metric.title}</Typography>
        </Grid>
        {metric.levels._ && (
          <Grid item>
            <Chip label={metric.levels._.value} />
          </Grid>
        )}
        {Object.entries(metric.levels)
          .filter(([severity]) => severity !== "_")
          .map(([severity, level]) => (
            <Grid item key="severity">
              <Chip label={`${level.value} ${severity}s`} />
            </Grid>
          ))}
      </Grid>
    </ListItem>
  );
}
