import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCheckAuth } from "ra-core";
import { FormEvent, useEffect } from "react";
import { useLogin } from "react-admin";
import { useNavigate } from "react-router-dom";
import FrontGate from "../FrontGate";
const PREFIX = "AzureADLoginPage";

const classes = {
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
};

const StyledFrontGate = styled(FrontGate)(({ theme }) => ({
  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/**
 * Display a login form that has a single "log on" button.
 */
const AzureADLoginPage = () => {
  const login = useLogin();
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return login({});
  };

  const checkAuth = useCheckAuth();
  const navigate = useNavigate();
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        navigate("/");
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, navigate]);

  return (
    <StyledFrontGate>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={classes.submit}
          fullWidth
        >
          Log på
        </Button>
      </form>
    </StyledFrontGate>
  );
};

export default AzureADLoginPage;
