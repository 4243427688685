import { Menu, MenuProps } from "react-admin";
import ModuleOverviewIcon from "@mui/icons-material/Extension";
import StatisticsIcon from "@mui/icons-material/TableChart";
import SiteIcon from "@mui/icons-material/Language";
import UserIcon from "@mui/icons-material/People";
import ReleaseIcon from "@mui/icons-material/Label";

export const SFMenu = (props: MenuProps) => (
  <Menu {...props}>
    <Menu.Item to="/sites" primaryText="Sites" leftIcon={<SiteIcon />} />
    <Menu.Item
      to="/releases"
      primaryText="Releases"
      leftIcon={<ReleaseIcon />}
    />
    <Menu.Item to="/users" primaryText="Users" leftIcon={<UserIcon />} />
    <Menu.Item
      to="/profileStatistics"
      primaryText="Statistics"
      leftIcon={<StatisticsIcon />}
    />
    <Menu.Item
      to="/modules"
      primaryText="Modules"
      leftIcon={<ModuleOverviewIcon />}
    />
  </Menu>
);
