import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import * as React from "react";
import { SiteListStatistics } from "../@types/sitefactory-types";
import { Link } from "react-admin";

interface TableProps {
  metricsData: SiteListStatistics;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SiteListNodes = (props: TableProps): JSX.Element => {
  // Unique node types in metrics.
  const header: string[] = ["Site", "Nodes", "Site statistics link"];
  const rows: string[][] = [[]];

  // Create map from all the metrics.
  // Also fills out moderationStates and nodeTypes with unique values, to
  // create knowledge of how many of each exist, and to enable iterating
  // through them.
  props.metricsData.metrics.forEach((metric: any) => {
    if (metric.nodes > 0) {
      rows.push([
        metric.site,
        metric.nodes,
        "/sites/" + metric.site + "/show/statistics",
      ]);
    }
  });

  return (
    <Container sx={{ padding: 0, marginLeft: 0 }} disableGutters>
      <Paper>
        <TableContainer>
          <Table>
            <TableRow sx={{ borderBottom: "unset" }}>
              <TableCell sx={{ width: 35, maxWidth: 35, paddingLeft: 0 }}>
                <IconButton
                  size="small"
                  onClick={() => props.setOpen(!props.open)}
                >
                  {props.open ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell sx={{ width: 350, maxWidth: 350 }}>
                {"Expand/collapse node data"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                <Chip label={rows.length - 1 + " sites."} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell padding="none" colSpan={3}>
                <Collapse in={props.open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Table size="small">
                      <TableHead>
                        {header.map((module) => {
                          return (
                            <TableCell
                              key={module}
                              sx={{
                                border: "1px solid lightgray",
                                position: "sticky",
                                top: 0,
                                minWidth: 130,
                                fontWeight: "bold",
                              }}
                            >
                              {module}
                            </TableCell>
                          );
                        })}
                      </TableHead>
                      <TableBody>
                        {rows.map((row, key) => (
                          <TableRow key={key}>
                            {row.map((col, key2) => {
                              if (key2 === row.length - 1) {
                                // Last cell has link button.
                                return (
                                  <TableCell
                                    key={key2}
                                    sx={{
                                      border: "1px solid lightgray",
                                    }}
                                  >
                                    <Button
                                      sx={{ textTransform: "none", p: 0 }}
                                      component={Link}
                                      to={{
                                        pathname: col,
                                      }}
                                    >
                                      Statistics for site
                                    </Button>
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    key={key2}
                                    sx={{
                                      border: "1px solid lightgray",
                                      "&:nth-child(1)": {
                                        minWidth: 400,
                                      },
                                    }}
                                  >
                                    {col}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export const SiteListParagraphs = (props: TableProps): JSX.Element => {
  // Unique node types in metrics.
  const header: string[] = ["Site", "Paragraphs", "Site statistics link"];
  const rows: string[][] = [[]];

  // Create map from all the metrics.
  // Also fills out moderationStates and nodeTypes with unique values, to
  // create knowledge of how many of each exist, and to enable iterating
  // through them.
  props.metricsData.metrics.forEach((metric: any) => {
    if (metric.paragraphs > 0) {
      rows.push([
        metric.site,
        metric.paragraphs,
        "/sites/" + metric.site + "/show/statistics",
      ]);
    }
  });

  return (
    <Container sx={{ padding: 0, marginLeft: 0 }} disableGutters>
      <Paper>
        <TableContainer>
          <Table>
            <TableRow sx={{ borderBottom: "unset" }}>
              <TableCell sx={{ width: 35, maxWidth: 35, paddingLeft: 0 }}>
                <IconButton
                  size="small"
                  onClick={() => props.setOpen(!props.open)}
                >
                  {props.open ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell sx={{ width: 350, maxWidth: 350 }}>
                {"Expand/collapse paragraph data"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                <Chip label={rows.length - 1 + " sites."} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell padding="none" colSpan={3}>
                <Collapse in={props.open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Table size="small">
                      <TableHead>
                        {header.map((module) => {
                          return (
                            <TableCell
                              key={module}
                              sx={{
                                border: "1px solid lightgray",
                                position: "sticky",
                                top: 0,
                                minWidth: 130,
                                fontWeight: "bold",
                              }}
                            >
                              {module}
                            </TableCell>
                          );
                        })}
                      </TableHead>
                      <TableBody>
                        {rows.map((row, key) => (
                          <TableRow key={key}>
                            {row.map((col, key2) => {
                              if (key2 === row.length - 1) {
                                // Last cell has link button.
                                return (
                                  <TableCell
                                    key={key2}
                                    sx={{
                                      border: "1px solid lightgray",
                                    }}
                                  >
                                    <Button
                                      sx={{ textTransform: "none", p: 0 }}
                                      component={Link}
                                      to={{
                                        pathname: col,
                                      }}
                                    >
                                      Statistics for site
                                    </Button>
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    key={key2}
                                    sx={{
                                      border: "1px solid lightgray",
                                      "&:nth-child(1)": {
                                        minWidth: 400,
                                      },
                                    }}
                                  >
                                    {col}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};
