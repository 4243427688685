import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { SiteStatusDetailed } from "../@types/sitefactory-types";
import InfoIcon from "@mui/icons-material/Info";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

/**
 * A Material UI card that shows the name of an info metric and its labels.
 */
export type InfoMetricProps = {
  metric: SiteStatusDetailed["metrics"][0];
};
export const InfoMetricCard = ({ metric }: InfoMetricProps) => {
  // Extract the name by dropping the _info suffix.
  const metricName = metric.name;
  let name = metricName.substring(0, metricName.length - "_info".length);
  name = name.charAt(0).toUpperCase() + name.substring(1);

  // Setup the table rows.
  const rows = [];
  for (const key in metric.labels) {
    rows.push({ name: key, value: metric.labels[key] });
  }

  return (
    <Card
      sx={{
        minWidth: 150,
      }}
    >
      <CardHeader
        title={name}
        avatar={metric.value ? <InfoIcon /> : <ErrorOutlineIcon />}
      />
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color="success.text.secondary"
          // we're not allowed to have a div at this point.
          component={"span"}
          gutterBottom
        >
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <b>{row.name}</b>
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
      </CardContent>
    </Card>
  );
};
