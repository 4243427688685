import {
  Array,
  Boolean,
  Dictionary,
  Literal,
  Optional,
  Number,
  Record,
  Static,
  String,
  Union,
} from "runtypes";

const AzureAdConfig = Record({
  // The "client id" or id of the Azure app that we use for authenticating users.
  azureAdAppId: String,
  // The Oauth scope we should request
  azureAdAppScope: String,
  // The login url for the authoritative AD
  azureAuthoritativeAd: String,
});

export const HexColorRunType = String.withBrand("HexColor").withConstraint(
  (color) =>
    new RegExp("^#[0-9a-f]{6}$", "i").test(color) ||
    `${color} is not a valid Hex color`,
  { name: "HexConstraint" }
);

export const ConfigurationRunType = Record({
  // Base domain for sites in the current environment.
  sitefactoryBaseDomain: String,
  // Endpoint to use to communicate with the SiteFactory API.
  sitefactoryApiEndpoint: String,
  // Main color for the environment.
  environmentMainColor: Optional(HexColorRunType),
  // How we authenticate users.
  authMode: Union(Literal("disabled"), Literal("AzureAD")),
  // Must be set if authMode is AzureAD
  azureAdConfig: Optional(AzureAdConfig),
  defaultBasicAuth: Boolean,
  // Dictionary of which roles should be displayed for each profile.
  displayedRoles: Optional(Dictionary(Array(String), String)),
  siteDefaults: Record({
    storageProviderName: Optional(String),
    backupCronSchedule: Optional(String),
    backupRetention: Optional(Number),
  }),
});

export type SiteFactoryAdminConfiguration = Static<typeof ConfigurationRunType>;
export type HexColor = Static<typeof HexColorRunType>;
