import React, { Fragment } from "react";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  TextField,
  TextInput,
} from "react-admin";
import { SFList } from "../components";
import BulkExportUserRoles from "./BulkExportUserRoles";

const UserListBulkActionButtons = (props: any) => (
  <Fragment>
    <BulkExportUserRoles {...props} />
    <BulkDeleteWithConfirmButton {...props} />
  </Fragment>
);

const postFilters = [
  <TextInput
    key="search"
    label="Search"
    source="q"
    resettable={true}
    alwaysOn
  />,
];

export const UserList: React.FC = (props) => (
  <SFList
    {...props}
    filters={postFilters}
    sort={{ field: "displayName", order: "ASC" }}
    bulkActionButtons={<UserListBulkActionButtons />}
  >
    <Datagrid rowClick="show">
      <TextField source="displayName" label="Ident" />
    </Datagrid>
  </SFList>
);
