import ReleaseIcon from "@mui/icons-material/Label";
import { ReleaseList } from "./ReleaseList";
import { ReleaseShow } from "./ReleaseShow";

const release = {
  icon: ReleaseIcon,
  list: ReleaseList,
  show: ReleaseShow,
};

export default release;
