import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { SiteStatusDetailed } from "../@types/sitefactory-types";
import { InfoMetricCard } from "./InfoMetricCard";
import { MetricCard } from "./MetricCard";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { MetricList, MetricListItem, MetricsCategories } from "./MetricList";
import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InfoIcon from "@mui/icons-material/Info";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

type Metric = SiteStatusDetailed["metrics"][0];

// HighlightedMetric allows value to be a string, too.
type HighlightedMetric = {
  [k in keyof Metric]: k extends "value" ? string | number : Metric[k];
};
type InfoTable = {
  items: Record<string, string>[];
  columns: string[];
};

export type SiteMetricsProps = { statusDetailed: SiteStatusDetailed };
export const SiteMetrics = React.memo(function SiteMetrics({
  statusDetailed,
}: SiteMetricsProps) {
  const {
    highlightedMetrics,
    metricsCategories,
    infoCards,
    infoTables,
    initialActiveTab: firstACtiveTab,
  } = useMemo(() => {
    const highlightedMetrics: HighlightedMetric[] = [];
    const metricsCategories: MetricsCategories = {};
    const groupedInfoItems: Record<string, any> = {};
    const infoTables: Record<string, InfoTable> = {};
    const infoCards = [];

    for (const metric of statusDetailed.metrics) {
      // Special case: always show drupal_bootstrap_status in highlights
      if (metric.name === "drupal_bootstrap_status") {
        highlightedMetrics.push({
          ...metric,
          value: metric.status === "ERROR" ? "Failing" : "OK",
        });
        continue;
      }

      // Info-metrics are always 1, what we're really looking at is their labels.
      if (metric.status === "INFO") {
        const name = metric.name.endsWith("_info")
          ? metric.name.slice(0, 0 - "_info".length)
          : metric.name;

        if (!groupedInfoItems[name]) {
          groupedInfoItems[name] = [];
        }
        metric.labels = cleanLabels(metric.labels);

        groupedInfoItems[name].push(metric);
        continue;
      }

      if (["WARN", "ERROR"].includes(metric.status) && metric.value > 0) {
        highlightedMetrics.push(metric);
        continue;
      }

      if (!metricsCategories[metric.category]) {
        metricsCategories[metric.category] = [];
      }
      let categorizedMetric = metricsCategories[metric.category].find(
        (m) => m.title === metric.title
      );

      if (!categorizedMetric) {
        categorizedMetric = {
          title: metric.title,
          levels: {},
        };
        metricsCategories[metric.category].push(categorizedMetric);
      }

      categorizedMetric.levels[metric.severity || "_"] = {
        value: metric.value,
        status: metric.status,
      };
    }

    for (const [name, metrics] of Object.entries(groupedInfoItems)) {
      if (metrics.length === 1) {
        infoCards.push(<InfoMetricCard metric={metrics[0]} />);
        continue;
      }
      const items = metrics.map((metric: any) => {
        return {
          "": metric.value ? <InfoIcon /> : <ErrorOutlineIcon />,
          ...metric.labels,
        };
      });
      infoTables[name] = {
        columns: Object.keys(items[0]),
        items,
      };
    }

    return {
      highlightedMetrics,
      metricsCategories,
      groupedInfoItems,
      infoCards,
      infoTables,
      initialActiveTab: Object.keys(infoTables)[0],
    };
  }, [statusDetailed.metrics]);

  const [activeTableTab, setActiveTableTab] = useState<string>(firstACtiveTab);

  const activeTable = useMemo(() => {
    if (activeTableTab in infoTables) {
      return infoTables[activeTableTab];
    }
    const newEntry = Object.entries(infoTables)[0];
    if (newEntry) {
      const [newTab, newTable] = newEntry;
      setActiveTableTab(newTab);
      return newTable;
    }
  }, [infoTables, activeTableTab]);

  const helpIcon = (
    <HelpOutlineIcon
      sx={{
        fontSize: 14,
        paddingBottom: 0.5,
        textAnchor: "top",
      }}
    />
  );
  const toolTipInformation =
    "Labels on _info metrics scraped from the sites at regular intervals. May be minutes out of sync with the status reports.";
  const toolTipMetrics =
    "Metrics scraped from the sites at regular intervals. May be minutes out of sync with the status reports. Inspect status-reports for details on errors and warnings.";
  const toolTipHighlightedMetrics =
    "Highlighted metrics are chosen because they require your special attention on this site and likely require action";
  return (
    <Grid container>
      {highlightedMetrics.length > 0 && (
        <>
          <h3>
            Highlighted metrics
            <Tooltip title={toolTipHighlightedMetrics}>{helpIcon}</Tooltip>
          </h3>
          <Grid container spacing={4}>
            {highlightedMetrics.map(
              ({ name, status, category, title, value, severity }) => (
                <Grid key={name} item>
                  <MetricCard
                    status={status}
                    category={category}
                    title={title}
                    value={value}
                    severity={severity}
                  />
                </Grid>
              )
            )}
          </Grid>
        </>
      )}
      <h3>
        Information
        <Tooltip title={toolTipInformation}>{helpIcon}</Tooltip>
      </h3>
      <Grid container spacing={4}>
        {infoCards.map((e, index) => (
          <Grid key={index} item>
            {e}
          </Grid>
        ))}
      </Grid>
      <Grid container direction="column">
        {Object.keys(infoTables).length > 0 && activeTable && (
          <>
            <Box
              sx={{
                marginTop: 4,
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <Tabs
                value={activeTableTab}
                onChange={(e, value) => setActiveTableTab(value)}
              >
                {Object.entries(infoTables).map(([name, table]) => {
                  return (
                    <Tab
                      label={`${name} (${table.items.length})`}
                      value={name}
                      key={name}
                    />
                  );
                })}
              </Tabs>
            </Box>

            <TableContainer
              component={Paper}
              sx={{
                marginBottom: 8,
                height: 300,
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {activeTable.columns.map((column) => (
                      <TableCell key={column}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeTable.items.map((item, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {activeTable.columns.map((column) => (
                        <TableCell component="th" scope="row" key={column}>
                          {item[column]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Grid>
      <h3>
        Metrics <Tooltip title={toolTipMetrics}>{helpIcon}</Tooltip>
      </h3>
      <Grid container spacing={4}>
        {Object.entries(metricsCategories).map(([category, metrics]) => (
          <Grid key={category} item>
            <MetricList title={category}>
              {metrics.map((metric) => (
                <MetricListItem key={category + metric.title} metric={metric} />
              ))}
            </MetricList>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
});

function cleanLabels(labels: { [key: string]: string }) {
  const result: { [key: string]: string } = {};

  for (const [key, value] of Object.entries(labels)) {
    const readableKey = key.startsWith("exported_")
      ? key.slice("exported_".length)
      : key;

    result[readableKey] = value;
  }

  return result;
}
