import { FieldProps, useRecordContext } from "react-admin";
import { Site } from "../../@types/sitefactory-types";

const CompactDomainsField: React.FC<FieldProps> = () => {
  const record = useRecordContext<Site>();

  if (!record || !record.primaryDomain) {
    return null;
  }

  let count = "";
  if (
    record &&
    record?.siteDomains &&
    Array.isArray(record.siteDomains) &&
    record.siteDomains.length > 0
  ) {
    count = " (" + record.siteDomains.length + ")";
  }

  return (
    <div>
      <a href={"https://" + record.primaryDomain}>{record.primaryDomain}</a>
      {count}
    </div>
  );
};

export default CompactDomainsField;
