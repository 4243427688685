import * as React from "react";
import { useDataProvider, useGetOne, useRecordContext } from "react-admin";
import { useQuery } from "react-query";

import {
  Site,
  SiteStatusDetailed,
  SiteStatusReportList,
} from "../@types/sitefactory-types";
import { SiteMetrics } from "./SiteStatusMetricCards";
import { SiteStatusReports } from "./SiteStatusReports";

export const SiteStatusShow = () => {
  const site = useRecordContext<Site>();

  // Fetch and render metrics.
  const {
    data: metricsData,
    isLoading: metricsIsLoading,
    error: metricsError,
  } = useGetOne<SiteStatusDetailed>("siteStatus", {
    id: site.id,
  });

  let metrics;
  if (metricsIsLoading) {
    metrics = (
      <span>
        <h3>Metrics</h3>
        <p>Loading metrics for {site.id}...</p>
      </span>
    );
  } else if (
    metricsError ||
    metricsData === undefined ||
    metricsData.metrics === undefined
  ) {
    metrics = (
      <span>
        <h3>Metrics</h3>
        <p>Could not load metrics for {site.id}</p>
      </span>
    );
  } else {
    metrics = <SiteMetrics statusDetailed={metricsData} />;
  }

  // Fetch and render status reports.
  const dataProvider = useDataProvider();
  const reportLimit = 5;
  const {
    data: statusReportList,
    isLoading: reportsIsLoading,
    error: reportsError,
  } = useQuery<{ data: SiteStatusReportList }>(
    ["siteStatusReport", { id: site.id, limit: reportLimit }],
    () => dataProvider.getSiteStatusReport(site.id, reportLimit)
  );

  let statusReports;
  if (reportsIsLoading) {
    statusReports = <p>Loading status reports for {site.id} ... </p>;
  } else if (reportsError || statusReportList === undefined) {
    statusReports = <p>Could not load status reports for {site.id}</p>;
  } else if (statusReportList.data.length === 0) {
    statusReports = (
      <span>
        <h3>Latest reports</h3>
        <p>No status reports found</p>
      </span>
    );
  } else {
    statusReports = (
      <SiteStatusReports statusReportList={statusReportList.data} />
    );
  }

  return (
    <span>
      {metrics}
      {statusReports}
    </span>
  );
};
