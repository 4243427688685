import React from "react";
import { BooleanInput, Create, SimpleForm } from "react-admin";
import { useLocation } from "react-router";

export const BackupJobCreate: React.FC = (props) => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const siteId = search.get("siteId");

  if (!siteId) {
    return <p>Unable to create backup job, no site name presented.</p>;
  }

  return (
    <Create {...props} redirect={`/sites/${siteId}/show/backup-jobs`}>
      <SimpleForm defaultValues={{ siteId }}>
        <BooleanInput
          label="Are you sure you want to create a back up?"
          source="confirm"
        />
      </SimpleForm>
    </Create>
  );
};
