import React from "react";
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
} from "react-admin";

import { useLocation } from "react-router";
import { SiteFactoryAdminConfiguration } from "../config";
import SelectRolesInput from "./inputs/SelectRolesInput";

export function userSiteRelationCreateFactory(
  config: SiteFactoryAdminConfiguration
): React.FC {
  const UserSiteRelationCreate: React.FC = (props: any) => {
    const location = useLocation();
    const search = new URLSearchParams(location.search);
    const siteId = search.get("siteId");

    return (
      <Create {...props} redirect={`/sites/${siteId}/show/access`}>
        <SimpleForm defaultValues={{ siteId }}>
          <ReferenceInput label="User" source="userId" reference="users">
            <AutocompleteInput optionText="displayName" />
          </ReferenceInput>
          <SelectRolesInput
            config={config}
            siteId={siteId}
            source="userRoles"
          />
        </SimpleForm>
      </Create>
    );
  };
  return UserSiteRelationCreate;
}
