import React from "react";
import {
  Edit,
  Identifier,
  RaRecord,
  SimpleForm,
  useBasename,
} from "react-admin";

import { SiteFactoryAdminConfiguration } from "../config";
import SelectRolesInput from "./inputs/SelectRolesInput";

export function userSiteRelationEditFactory(
  config: SiteFactoryAdminConfiguration
): React.FC<RaRecord> {
  const UserSiteRelationEdit: React.FC<RaRecord> = (props: any) => {
    const basename = useBasename();
    const doRedirect = (
      _resource?: string,
      _id?: Identifier,
      data?: Partial<RaRecord>,
      _state?: object
    ) => {
      // Redirect back to the site access tab after edit.
      const destination = `${basename}sites/${data?.siteId}/show/access`;
      return destination;
    };

    return (
      <Edit {...props} redirect={doRedirect} mutationMode={"pessimistic"}>
        <SimpleForm>
          <SelectRolesInput config={config} source="userRoles" />
        </SimpleForm>
      </Edit>
    );
  };
  return UserSiteRelationEdit;
}
