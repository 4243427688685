import { useRecordContext } from "react-admin";
import { LogResults } from "../@types/sitefactory-types";

const LogOutput = (_props: any) => {
  const record = useRecordContext<LogResults>();
  if (!record.logs || !record.logs) {
    return <div>error</div>;
  }

  return (
    <div>
      {record.logs.map((log, i) => {
        // Return the element. Also pass key
        return <pre key={i}>{log}</pre>;
      })}
    </div>
  );
};

export { LogOutput };
