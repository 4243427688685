import { List } from "react-admin";
import { SFDefaultPager } from ".";

const SFList = (props: any) => (
  <List pagination={<SFDefaultPager />} {...props} />
);

SFList.defaultProps = {
  perPage: 10,
};

export { SFList };
