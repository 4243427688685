import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

export const UserEdit: React.FC = (props) => (
  <Edit mutationMode={"pessimistic"} {...props}>
    <SimpleForm>
      <TextInput
        source="kkIdent"
        label="KK Ident"
        helperText="The internal KK identification."
      />
      <TextInput
        source="externalId"
        label="External ID"
        helperText="The email in B2C"
      />
    </SimpleForm>
  </Edit>
);
