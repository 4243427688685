import { SelectArrayInputProps } from "ra-ui-materialui";
import { SelectArrayInput, useGetOne, useRecordContext } from "react-admin";
import { UserSiteRelation } from "../../@types/sitefactory-types";
import { SiteFactoryAdminConfiguration } from "../../config";
import roles from "../roles";

export const SelectRolesInput = (
  props: SelectArrayInputProps & {
    siteId?: string | null;
    config: SiteFactoryAdminConfiguration;
  }
) => {
  const { config, siteId: propSiteId, ...remainingProps } = props;
  let siteId = propSiteId;
  const record = useRecordContext<UserSiteRelation>(props);

  if (record?.siteId) {
    siteId = record.siteId;
  }

  const {
    data: siteData,
    isLoading: siteIsLoading,
    error: siteError,
  } = useGetOne("sites", { id: siteId });
  const releaseID = siteData?.releaseID || "";
  const {
    data: releaseData,
    isLoading: releaseIsLoading,
    error: releaseError,
  } = useGetOne("releases", { id: releaseID }, { enabled: releaseID !== "" });

  if (!siteId) {
    return <p>Unable to create user site relation, no site name presented.</p>;
  }

  if (siteIsLoading || releaseIsLoading) {
    return (
      <SelectArrayInput
        {...remainingProps}
        disabled={siteIsLoading || releaseIsLoading}
        choices={[]}
      />
    );
  }

  if (siteError || releaseError) {
    return <p>Unable to create user site relation, error getting site data.</p>;
  }

  const siteProfile = releaseData.siteProfile;
  const displayedRolesConfig = config?.displayedRoles?.[siteProfile] ||
    config?.displayedRoles?.["default"] || [
      "editor",
      "site_editor",
      "webmaster",
    ];
  const displayedRoles = roles.filter((role) => {
    return displayedRolesConfig.includes(role["id"]);
  });

  // If the displayed roles have updated for a profile, then we need to remove
  // roles that were potentially previously allowed. We do that by filtering
  // the userRoles field on the record with the currently allowed roles.
  if (record?.userRoles) {
    record.userRoles = record.userRoles.filter((userRole) => {
      return displayedRolesConfig.includes(userRole);
    });
  }

  return <SelectArrayInput {...remainingProps} choices={displayedRoles} />;
};

export default SelectRolesInput;
