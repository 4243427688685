import React from "react";
import {
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import { LogOutput } from "../components";

export const RestoreJobShow: React.FC = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="general info">
          <TextField source="status" label="Status" />
          <TextField label="Target" source="restoreFileName" />
          <TextField source="created" label="Creater" />
          <TextField source="completionTime" label="Completed" />
          <ReferenceField
            source="id"
            label="Logs"
            reference="logs"
            link={false}
          >
            <LogOutput source="logs" />
          </ReferenceField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
