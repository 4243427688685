import React from "react";
import { BooleanInput, Create, SimpleForm } from "react-admin";
import { useLocation } from "react-router";

export const LoginJobCreate: React.FC = (props) => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const siteId = search.get("siteId");

  if (!siteId) {
    return <p>Unable to create generate login, no site name presented.</p>;
  }

  return (
    <Create {...props} redirect={`/sites/${siteId}/show/admin-logins`}>
      <SimpleForm defaultValues={{ siteId }}>
        <BooleanInput
          label="Are you sure you want to enable user 1 and generate login?"
          source="confirm"
        />
      </SimpleForm>
    </Create>
  );
};
