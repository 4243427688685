import {
  SiteStatusReport,
  SiteStatusReportList,
} from "../@types/sitefactory-types";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import ReactJson from "react-json-view";

export type StatusReportsProps = { statusReportList: SiteStatusReportList };
export const SiteStatusReports = ({ statusReportList }: StatusReportsProps) => {
  const statusReports = [];
  for (const entry of statusReportList) {
    const [timestamp, data] = entry;
    const date = new Date(timestamp).toLocaleString();
    const type = data.meta?.reportType || Object.keys(data.report).join(", ");
    const { meta } = data as SiteStatusReport;
    let icon;
    if (meta.errors && meta.errors.length > 0) {
      icon = <ErrorIcon color="error" sx={{ marginRight: 2 }} />;
    } else {
      icon = <DoneIcon color="success" sx={{ marginRight: 2 }} />;
    }
    statusReports.push(
      <Accordion defaultExpanded={false} key={timestamp}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {icon}
          <Typography>
            {date}: {type}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReactJson src={data} name={null} />
        </AccordionDetails>
      </Accordion>
    );
  }

  const helpIcon = (
    <HelpOutlineIcon
      sx={{
        fontSize: 14,
        paddingBottom: 0.5,
        textAnchor: "top",
      }}
    />
  );
  const toolTipReport = `Latest status reports pushed from the site status operators.`;

  return (
    <span>
      <h3>
        Latest reports <Tooltip title={toolTipReport}>{helpIcon}</Tooltip>
      </h3>
      {statusReports}
    </span>
  );
};
