import ActionUpdate from "@mui/icons-material/Download";
import { format } from "date-fns/fp";
import { Button, downloadCSV, useGetMany, useListContext } from "react-admin";
import jsonExport from "jsonexport/dist";
import { useEffect, useState } from "react";
import { SiteFactoryAdminConfiguration } from "../config";

const BulkExportSiteInformation = (config: SiteFactoryAdminConfiguration) => {
  // The sites the user has selected in the list view.
  const { selectedIds } = useListContext();
  // Whether button has been clicked and code is running
  const [clicked, setClicked] = useState(false);
  //  Whether the bulk export button is enabled.
  const [buttonEnabled, setButtonEnabled] = useState(false);

  // Fetch data for all selected sites.
  const { data: selectedData = [], isLoading: selectedLoading } = useGetMany(
    "sites",
    { ids: selectedIds || [] }
  );

  // Filter list of release-ids from selected sites.
  const releases = selectedData.map((siteInformation) => {
    return siteInformation.releaseID;
  });

  // Fetch data for releases from the filtered release-id list.
  const { data: selectedReleases = [], isLoading: releasesLoading } =
    useGetMany("releases", { ids: releases || [] });

  // Re-render on state changes.
  useEffect(() => {
    // We're still loading the selected sites or corresponding releases.
    if (selectedLoading || releasesLoading) {
      setButtonEnabled(false);
      return;
    }

    // We don't have any data (release id) on the sites.
    if (!selectedData) {
      setButtonEnabled(false);
      return;
    }

    // No sites selected, or the last site just got unchecked.
    if (selectedData.length === 0) {
      setButtonEnabled(false);
      return;
    }
    setButtonEnabled(true);

    if (clicked) {
      const siteInformationsForExport = selectedData.map((siteInformation) => {
        // Select values from sites data.
        const siteInformationForExport = (({
          id,
          profile,
          primaryDomain,
          allDomains,
          live,
          authEnabled,
        }) => ({
          id,
          profile,
          primaryDomain,
          allDomains,
          live,
          authEnabled,
        }))(siteInformation);

        // Site live-status is determined from whether sitefactoryBaseDomain matches
        // any of the site domains.
        siteInformationForExport.live = false;
        for (const domain of siteInformationForExport.allDomains) {
          if (!domain.endsWith(config.sitefactoryBaseDomain)) {
            siteInformationForExport.live = true;
          }
        }

        // Site profile is obtained through release id.
        const releaseInformation = selectedReleases.find(
          (site) => site.id === siteInformation.releaseID
        );
        siteInformationForExport.profile = releaseInformation.siteProfile;

        return siteInformationForExport;
      });
      // sort by id
      siteInformationsForExport.sort((a, b) => (a.id < b.id ? -1 : 1));
      jsonExport(
        siteInformationsForExport,
        {
          // order fields in the export
          headers: [
            "id",
            "profile",
            "primaryDomain",
            "allDomains",
            "live",
            "authEnabled",
          ],
          rowDelimiter: ";",
        },
        (err, csv) => {
          // download as 'site_information_[date].csv' file
          downloadCSV(
            csv,
            "site_information_" + format("yyyy-MM-dd_HH-mm-ss", new Date())
          );
        }
      );
      setClicked(false);
    }
  }, [
    selectedIds,
    selectedLoading,
    selectedData,
    selectedReleases,
    clicked,
    releasesLoading,
    config.sitefactoryBaseDomain,
  ]);

  const handleClick = () => {
    setClicked(true);
  };

  return (
    <>
      <Button
        label="Export site information"
        onClick={handleClick}
        disabled={!buttonEnabled}
      >
        <ActionUpdate />
      </Button>
    </>
  );
};

export default BulkExportSiteInformation;
