import React from "react";
import { Show, TextField } from "react-admin";

export const JobLogsShow: React.FC = (props) => {
  return (
    <Show {...props}>
      <TextField source="logs" label="Logs" />
    </Show>
  );
};
