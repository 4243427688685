import ErrorIcon from "@mui/icons-material/Error";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { red, amber, grey } from "@mui/material/colors";
import { Severity } from "../@types/sitefactory-types";

export type MetricCardProps = {
  category: string;
  title: string;
  value: string | number;
  status: Severity | "INFO";
  severity?: string;
};

/**
 * A Material UI Card that depicts the name and value of a metrics optionally
 * with a severity indication.
 */
export const MetricCard = ({
  category,
  title,
  value,
  status,
  severity,
}: MetricCardProps) => {
  let icon;
  switch (status) {
    case "WARN": {
      icon = (
        <Tooltip title="Examine both failing and ok status-reports for details. Be aware that this metric may be minutes old.">
          <WarningAmberIcon
            color="warning"
            data-status-value="1"
            data-status-name="warn"
          />
        </Tooltip>
      );
      break;
    }

    case "ERROR": {
      icon = (
        <Tooltip title="See failing status-reports for details. Be aware that this metric may be minutes old.">
          <ErrorIcon
            color="error"
            data-status-value="2"
            data-status-name="error"
          />
        </Tooltip>
      );
      break;
    }
  }

  return (
    <Card
      sx={{
        minHeight: 180,
        minWidth: 220,
      }}
    >
      <CardHeader
        disableTypography={true}
        title={<Typography variant="body1">{title}</Typography>}
        subheader={
          <Typography
            variant="body2"
            color={grey[800]}
            textTransform="uppercase"
          >
            {category}
          </Typography>
        }
        avatar={icon || undefined}
      />
      <CardContent
        sx={{
          bgcolor: getStatusBackgroundColor(status),
        }}
      >
        <Typography variant="h5" component="div" textAlign="center">
          {value}
        </Typography>
        {severity && (
          <Typography variant="subtitle2" textAlign="center">
            {severity}s
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const statusBackgroundColor: Record<string, string> = {
  WARN: amber[100],
  ERROR: red[100],
};
function getStatusBackgroundColor(status: Severity | "INFO") {
  return statusBackgroundColor[status] || undefined;
}
