import React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  EditButton,
  FunctionField,
  RaRecord,
  ReferenceManyField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import { SFDefaultPager } from "../components";
import roles from "../userSiteRelation/roles";

export const UserShow: React.FC = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="user info">
        <TextField source="kkIdent" label="KK Ident" />
        <TextField source="externalId" label="External ID" />
      </Tab>
      <Tab label="sites" path="sites">
        <ReferenceManyField
          reference="userSiteRelations"
          sort={{ field: "siteId", order: "ASC" }}
          pagination={<SFDefaultPager />}
          // ReferenceManyField comes with it's own default for perPage so we
          // have to override.
          perPage={10}
          target="userId"
        >
          <Datagrid>
            <TextField label="Site" source="siteId" />
            <ArrayField label="Roles" source="userRoles">
              <SingleFieldList linkType={false}>
                <FunctionField
                  render={(role: any) => {
                    const roleDisplayName = roles.find(
                      (row: RaRecord) => row.id === role
                    )?.name;
                    return (
                      <ChipField
                        source="name"
                        record={{ name: roleDisplayName }}
                      />
                    );
                  }}
                />
              </SingleFieldList>
            </ArrayField>
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
