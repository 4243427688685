import { createTheme, Theme } from "@mui/material";
import { defaultTheme } from "react-admin";

import { clone, merge } from "lodash";
import { SiteFactoryAdminConfiguration } from "../config";

/**
 * Default theme customized for WebPlatform
 */
export const WebplatformTheme = (
  config: SiteFactoryAdminConfiguration
): Theme => {
  const theme = clone(defaultTheme);

  if (config.environmentMainColor) {
    // Set the top-menu color if configured to do so.
    merge(theme, {
      palette: {
        secondary: {
          main: config.environmentMainColor,
        },
      },
    });
  }
  return createTheme(theme);
};
