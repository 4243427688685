import React from "react";
import {
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import JSONPretty from "react-json-pretty";

export const ReleaseShow: React.FC = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="images info">
        <TextField source="id" label="Name" />
        <TextField source="siteProfile" label="Site Profile" />
        <TextField source="backupImageTag" label="Backup Image tag" />
        <TextField source="backupImageUrl" label="Backup Image URL" />
        <TextField source="nginxImageTag" label="NGINX Image tag" />
        <TextField source="nginxImageUrl" label="NGINX Image URL" />
        <TextField source="phpImageTag" label="PHP Image tag" />
        <TextField source="phpImageUrl" label="PHP Image URL" />
        <TextField source="redisImageTag" label="Redis Image tag" />
        <TextField source="redisImageUrl" label="Redis Image URL" />
        <TextField source="solrImageTag" label="Solr Image tag" />
        <TextField source="solrImageUrl" label="Solr Image URL" />
        <TextField source="varnishImageTag" label="Varnish Image tag" />
        <TextField source="varnishImageUrl" label="Varnish Image URL" />
      </Tab>
      <Tab label="manifest" path="manifest">
        <FunctionField
          label="Manifest"
          render={(record: any) => {
            let manifest;
            if (record?.releaseManifest) {
              try {
                manifest = JSON.parse(atob(record.releaseManifest));
              } catch (err) {}
            }
            if (manifest) {
              return <JSONPretty data={manifest} />;
            }
          }}
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
