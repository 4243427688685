import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
} from "@mui/material";
import { red, amber, green } from "@mui/material/colors";
import { visuallyHidden } from "@mui/utils";
import { compareVersions, validate } from "compare-versions";
import { SetStateAction, useState } from "react";
import { SelectInput, useGetList } from "react-admin";
import { useForm, FormProvider } from "react-hook-form";
import * as React from "react";

interface filterProps {
  setDirectDependencyFilter: React.Dispatch<SetStateAction<string>>;
  setAbandonedFilter: React.Dispatch<SetStateAction<string>>;
}

interface EnhancedTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  head: string[];
}

type Order = "asc" | "desc";

// Toolbar with dropdown filters.
function DropdownTableToolbar(props: filterProps) {
  const methods = useForm();
  return (
    <Toolbar disableGutters>
      <FormProvider {...methods}>
        <SelectInput
          sx={{ minWidth: 250, mr: "16px" }}
          alwaysOn
          source="direct dependency"
          defaultValue={"true"}
          choices={[
            { id: "true", name: "Yes" },
            { id: "false", name: "No" },
          ]}
          emptyValue={""}
          onChange={(event) =>
            props.setDirectDependencyFilter(event.target.value)
          }
          helperText={false}
        />
        <SelectInput
          sx={{ minWidth: 250, mr: "16px" }}
          source="abandoned"
          choices={[
            { id: "true", name: "Yes" },
            { id: "false", name: "No" },
          ]}
          onChange={(event) => props.setAbandonedFilter(event.target.value)}
          helperText={false}
        />
      </FormProvider>
    </Toolbar>
  );
}

// Table header with ordering buttons.
function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { order, orderBy, onRequestSort, head } = props;

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 3,
      }}
    >
      <TableRow>
        <TableCell
          sx={{
            backgroundColor: "white",
            borderRight: "1px solid lightgray",
            position: "sticky",
            left: 0,
            minWidth: 150,
            zIndex: 3,
            borderTopLeftRadius: "4px",
          }}
          sortDirection={orderBy === "Profile name" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "Profile name"}
            direction={orderBy === "Profile name" ? order : "asc"}
            onClick={createSortHandler("Profile name")}
          >
            {"Profile name"}
            {orderBy === "Profile name" ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        {head.map((module) => {
          return (
            <TableCell
              key={module}
              align="center"
              sx={{
                backgroundColor: "white",
                borderRight: "1px solid lightgray",
                position: "sticky",
                top: 0,
                minWidth: 130,
                "&:last-child": {
                  borderTopRightRadius: "4px",
                },
              }}
            >
              {module}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export const ModuleOverview = (): JSX.Element => {
  const [directDependencyFilter, setDirectDependencyFilter] = useState("true");
  const [abandonedFilter, setAbandonedFilter] = useState("");
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("Profile name");
  // Fetch all releases.
  const { data: selectedReleases } = useGetList("releases", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "id", order: "ASC" },
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    let setDesc: boolean;
    if (property === "Profile name") {
      // Profile default sorts as ascending.
      setDesc = orderBy === property && order === "asc";
    } else {
      // Modules default sorts as descending.
      setDesc = !(orderBy === property && order === "desc");
    }
    setOrder(setDesc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  if (selectedReleases) {
    const profileList: any[] = [];

    /**
     * Make list of releases.
     * Exludes invalid releases like pr-site and snapshot tests by checking
     * version formatting.
     */
    for (const val of selectedReleases) {
      if (validate(val.id.replace(val.siteProfile + "-", ""))) {
        // Discard versions with old manifests.
        const manifestCheck = JSON.parse(atob(val.releaseManifest)).composer
          ?.installed[0].abandoned;
        if (manifestCheck !== undefined) {
          profileList.push(val);
        }
      }
    }
    // Filter only newest releases.
    for (let index = 0; index < profileList.length; index++) {
      const profile = profileList[index].siteProfile;
      if (index !== profileList.length - 1) {
        while (profileList[index + 1].siteProfile === profile) {
          profileList.splice(index, 1);
          // End of array check.
          if (index + 1 === profileList.length) {
            break;
          }
        }
      }
    }

    // Prepare combined string array.
    let tableArray: string[][] = [["", "Release name"]];
    for (const profile of profileList) {
      tableArray.push([profile.siteProfile, profile.id]);
    }
    let moduleCount = 0;
    const moduleVersion = new Map<string, string>();
    // Iterate on all profiles.
    for (let profile = 0; profile < profileList.length; profile++) {
      // Insert empty values for all known modules.
      for (let i = 0; i < moduleCount; i++) {
        tableArray[profile + 1].push("");
      }

      let installedModules: any[];
      try {
        installedModules = JSON.parse(
          atob(profileList[profile].releaseManifest)
        ).composer.installed;
      } catch (error) {
        installedModules = [];
      }
      // Iterate on all modules in the releaseManifest.
      for (const module of installedModules) {
        // Compare with filters.
        const directDependencyString = "" + module["direct-dependency"];
        const abandonedString = "" + module["abandoned"];
        if (
          (directDependencyFilter === "" ||
            directDependencyFilter === directDependencyString) &&
          (abandonedFilter === "" || abandonedFilter === abandonedString)
        ) {
          const moduleIndex = tableArray[0].indexOf(module.name);
          if (moduleIndex < 0) {
            tableArray[0].push(module.name);
            tableArray[profile + 1][tableArray[0].length - 1] = module.version;
            moduleCount++;
            if (module.version.startsWith("dev")) {
              moduleVersion.set(module.name, "0.0.0");
            } else {
              moduleVersion.set(module.name, module.version);
            }
          } else {
            tableArray[profile + 1][moduleIndex] = module.version;
            // Compare version and update the version map if it is newer.
            let compareA = "0.0.0";
            let compareB = "0.0.0";
            if (validate(moduleVersion.get(module.name) ?? "")) {
              compareA = moduleVersion.get(module.name) ?? "";
            }
            if (validate(module.version)) {
              compareB = module.version;
            }
            if (compareVersions(compareA, compareB) < 0) {
              moduleVersion.set(module.name, module.version);
            }
          }
        }
      }
    }

    // Fill empty spaces in 2d array.
    const tableWidth = tableArray[0].length;
    tableArray.forEach((row) => {
      for (let i = row.length; i < tableWidth; i++) {
        row.push("");
      }
    });

    // Ordering by profile name or module version.
    const first: string[] = tableArray.shift() ?? [""];
    const orderColumnIndex = first.indexOf(orderBy);
    const versionCompare = (a: string[], b: string[]) => {
      let compareA = "0.0.0";
      let compareB = "0.0.0";
      if (validate(a[orderColumnIndex])) {
        compareA = a[orderColumnIndex];
      }
      if (validate(b[orderColumnIndex])) {
        compareB = b[orderColumnIndex];
      }
      return compareVersions(compareA, compareB);
    };
    if (order === "desc") {
      tableArray.sort((a: string[], b: string[]) => {
        return orderBy === "Profile name"
          ? b[0].localeCompare(a[0])
          : versionCompare(b, a);
      });
    } else {
      tableArray.sort((a: string[], b: string[]) => {
        return orderBy === "Profile name"
          ? a[0].localeCompare(b[0])
          : versionCompare(a, b);
      });
    }
    tableArray.unshift(first);

    // Transpose table and sort by module name.
    tableArray = tableArray[0].map((_, colIndex) =>
      tableArray.map((row) => row[colIndex])
    );
    tableArray.sort((a: string[], b: string[]) => (a[0] < b[0] ? -1 : 1));

    const cols = Object.keys(tableArray[0]);
    const headProfile: string[] = tableArray.splice(0, 1)[0].slice(1);
    const headRelease: string[] = tableArray.splice(0, 1)[0].slice(1);

    return (
      <Container sx={{ minWidth: "100%", padding: 0 }} disableGutters>
        <DropdownTableToolbar
          setDirectDependencyFilter={setDirectDependencyFilter}
          setAbandonedFilter={setAbandonedFilter}
        />
        <Paper>
          <TableContainer sx={{ overflowX: "initial", mb: "8px" }}>
            <Table size="small" sx={{ borderCollapse: "separate" }}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                head={headProfile}
              />
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: "white",
                      borderRight: "1px solid lightgray",
                      borderBottom: "1px solid lightgray",
                      minWidth: 130,
                    }}
                  >
                    {"Release name"}
                  </TableCell>
                  {headRelease.map((module) => {
                    return (
                      <TableCell
                        key={module}
                        align="center"
                        sx={{
                          backgroundColor: "white",
                          borderRight: "1px solid lightgray",
                          borderBottom: "1px solid lightgray",
                          minWidth: 130,
                        }}
                      >
                        {module}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableArray.map((row, key) => (
                  <TableRow key={key}>
                    {cols.map((col) => {
                      let bgColor;
                      const colNum: number = +col;
                      if (!row[col as any]) {
                        // Empty fields are uncoloured.
                        bgColor = "white";
                      } else if (row[colNum].startsWith("dev")) {
                        // Dev versions are red.
                        bgColor = red[100];
                      } else if (
                        moduleVersion.get(tableArray[key][0]) === row[colNum]
                      ) {
                        // Newest version is green.
                        bgColor = green[100];
                      } else {
                        // Old versions are yellow.
                        bgColor = amber[100];
                      }
                      let cellStyle;
                      // First column is sticky.
                      if (col === "0") {
                        cellStyle = {
                          position: "sticky",
                          left: 0,
                          backgroundColor: "white",
                          borderRight: "1px solid lightgray",
                          textAlign: "left",
                        };
                      } else {
                        cellStyle = {
                          backgroundColor: bgColor,
                          borderRight: "1px solid lightgray",
                          textAlign: "center",
                        };
                      }
                      return (
                        <TableCell
                          key={col}
                          sx={cellStyle}
                          sortDirection={
                            orderBy === row[colNum] ? order : false
                          }
                        >
                          <TableSortLabel
                            className={colNum === 0 ? "" : "Mui-disabled"}
                            active={orderBy === row[colNum]}
                            direction={orderBy === row[colNum] ? order : "asc"}
                            onClick={createSortHandler(row[colNum])}
                          >
                            {row[col as any]}
                            {orderBy === row[colNum] ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    );
  } else {
    return <div className="ModuleOverview"></div>;
  }
};
