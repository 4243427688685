import { ReferenceInputProps } from "ra-ui-materialui";
import { ReferenceInput, useGetOne, useRecordContext } from "react-admin";

export const ReleaseIDEditInput = (props: ReferenceInputProps) => {
  const record = useRecordContext(props);
  const { data: release } = useGetOne("releases", {
    id: record.releaseID,
  });
  const releaseIDFilter = release?.siteProfile
    ? { siteProfile: release.siteProfile }
    : {};

  return (
    <ReferenceInput filter={releaseIDFilter} {...props}>
      {props.children}
    </ReferenceInput>
  );
};

export default ReleaseIDEditInput;
