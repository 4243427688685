import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";
import { useLocation } from "react-router";

export const RestoreJobCreate: React.FC = (props) => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const siteId = search.get("siteId");

  if (!siteId) {
    return <p>Unable to create backup job, no site name presented.</p>;
  }

  return (
    <Create {...props} redirect={`/sites/${siteId}/show/restore-jobs`}>
      <SimpleForm defaultValues={{ siteId }}>
        <TextInput label="Backup to restore from" source="restoreFileName" />
      </SimpleForm>
    </Create>
  );
};
